<template>
  <ion-content class="ion-text-center ion-padding-bottom" v-if="!formState.isLoading">

    <ion-row class="profile-section">
      <ion-col size="12">
        <span class="profile-photo">
          <img v-if="user.profile.profile_photo" :src="user.profile.profile_photo">
        </span>
      </ion-col>
      <ion-col size="12">
        <span style="font-family: 'Satisfy', cursive;color:#DC8D83;font-size:25px;text-transform:capitalize;padding-right:1vw">{{user.profile.first_name}}</span>
        <span style="font-weight:600;color:#004F7C;font-size:13px;text-transform:uppercase;">{{user.profile.last_name}}</span>
      </ion-col>
      <ion-col size="12">
        <span style="color:#9F9F9F;font-size:12px;">Joined {{moment(user.dates.created.toDate()).format('MMMM d, YYYY')}}</span>
      </ion-col>
    </ion-row>

    <ion-row class="form ion-text-center">
      <ion-col size="12">
        <ion-item class="border">
          <ion-label>Phone Number</ion-label>
          <ion-input inputmode="text" type="text" v-model="formState.phone" disabled />
        </ion-item>        
      </ion-col>
      <ion-col size="12">
        <ion-item>
          <ion-label>Username </ion-label>
          <ion-input inputmode="text" type="text" v-model="formState.username" @keyup="validateUsername" @ionBlur="updateUsername" />
        </ion-item> 
      </ion-col>
      <ion-col size="12" style="padding:1% 0 3%">
        <span v-if="formState.username && formState.username_validated && formState.username.length<6" style="display:block;text-align:left;font-size:12px;color:rgb(220, 141, 131)">Your username is too short</span>
        <span v-else-if="formState.username && !formState.username_validated" style="display:block;text-align:left;font-size:12px;color:rgb(220, 141, 131)">This username is taken</span>
      </ion-col>
      <ion-col size="12">
        <ion-item class="border">
          <ion-label>Email</ion-label>
          <ion-input inputmode="text" type="text" v-model="formState.email" @ionBlur="updateEmailAddress" />
        </ion-item>        
      </ion-col>
      <ion-col size="12">
        <ion-item class="border">
          <ion-label>First Name</ion-label>
          <ion-input inputmode="text" type="text" v-model="formState.first_name" @ionBlur="updateFirstName" />
        </ion-item>        
      </ion-col>
      <ion-col size="12">
        <ion-item class="border">
          <ion-label>Last Name</ion-label>
          <ion-input inputmode="text" type="text" v-model="formState.last_name" @ionBlur="updateLastName" />
        </ion-item>        
      </ion-col>
      <ion-col size="12">
        <ion-item class="border">
          <ion-label>Birthday</ion-label>
          <ion-datetime :value="formState.birthday" display-timezone="utc" @ionChange="updateBirthday($event.target.value)"></ion-datetime>
        </ion-item>        
      </ion-col>
      <ion-col size="12">
        <ion-item>
          <ion-label>Gender</ion-label>
          <ion-select interface="popover" placeholder="Select a gander" style="width:100%;max-width:100%;" @ionChange="updateGender($event.target.value)">
            <ion-select-option value="male">Male</ion-select-option>
            <ion-select-option value="female">Female</ion-select-option>
            <ion-select-option value="other">Other</ion-select-option>
          </ion-select>
        </ion-item>        
      </ion-col>
      <ion-col size="12">
        <ion-item>
          <ion-label>City</ion-label>
          <ion-input inputmode="text" type="text" v-model="formState.city" @ionChange="updateCity" />
        </ion-item>        
      </ion-col>
      <ion-col size="12">
        <ion-item>
          <ion-label>Country</ion-label>
          <ion-select interface="popover" @ionChange="updateCountry($event.target.value)">
            <ion-select-option value="cad">Canada</ion-select-option>
            <ion-select-option value="usa">United States</ion-select-option>
          </ion-select>
        </ion-item>        
      </ion-col>
    </ion-row>

    <ion-row class="settings-section">
      <ion-col size="12">
        <OutlinedButton :text="'Log Out'" :textcolor="'#00777E'" :color="'#00777E'" style="width:50%;margin:1vh auto 0" @click="logOut" />  
      </ion-col>
    </ion-row>

  </ion-content>
</template>

<script>
/* Vue/Vuex */
import { useStore } from 'vuex';
import { computed, watchEffect, reactive } from 'vue';
import { useRouter } from 'vue-router'

/* Firebase */
import { getAuth, signOut } from "firebase/auth";
const fb = require('@/firebase/init')

/* Mixins */
import {openToast} from '@/mixins/helpers'

/* Ionic */
import { IonPage, IonContent, IonRow, IonCol, IonImg, IonItem, IonLabel, IonInput, IonSelect, IonSelectOption, IonDatetime } from '@ionic/vue';

/* Plugins */
import moment from 'moment'

/* Components */
import RoundButton from '@/components/buttons/Solid';
import OutlinedButton from '@/components/buttons/Outlined.vue'

export default  {
  name: 'intro',

  components: {
    IonPage, IonContent, IonRow, IonCol, IonImg, IonItem, IonLabel, IonInput, IonSelect, IonSelectOption, IonDatetime,
    RoundButton, OutlinedButton
  },

  setup() {
    const { state } = useStore();
    const router = useRouter()
    const user = computed(() => state.auth.user);
    const uid = computed(() => state.auth.uid);
    const causes = computed(() => state.causes.causes);

    let formState = reactive({
      isLoading: true,
      username: '',
      username_validated: true,
      company_name: '',
      email: '',
      phone: null,   
      first_name:'',
      last_name:'',
      birthday: null,
      gender:null,
      city:'',
      country: '',
    });

    const validateUsername = async () => {
      fb.db.collection("Users").where("profile.username", "==", formState.username).get().then(snapshot => {
        formState.username_validated = snapshot.empty
      })
    }

    const updateUsername =  () => {
      if (user.value.profile.username!==formState.username && formState.username_validated && formState.username.length>5) {
        fb.db.collection("Users").doc(uid.value).update({
          'profile.username': formState.username.toLowerCase(),
        }).then(async () => {
          user.value.profile.username=formState.username.toLowerCase()
          await openToast('Your username has been successfully updated.')
          fb.db.collection("Users").doc(uid.value).collection("Tags").doc('profile-complete').set({'created':new Date()})
        }).catch(async (error) => {
          await openToast('There was an issue saving your username.')
        })
      }
    }

    const updateEmailAddress = async () => {
      if (formState.email && user.value.profile.phone!==formState.phone) {
        fb.db.collection("Users").doc(uid.value).update({
          'profile.email': formState.email
        }).then(async () => {
          user.value.profile.email=formState.email
          await openToast('Your email has been successfully updated.')
        }).catch(async (error) => {
          await openToast('There was an issue saving your email.')
        })
      }
    }

    const updateFirstName =  () => {
      if (user.value.profile.first_name!==formState.first_name) {
        fb.db.collection("Users").doc(uid.value).update({
          'profile.first_name': formState.first_name.toLowerCase(),
        }).then(async () => {
          user.value.profile.first_name=formState.first_name.toLowerCase()
          await openToast('Your first name has been successfully updated.')
        }).catch(async (error) => {
          await openToast('There was an issue saving your first name.')
        })
      }
    }

    const updateLastName =  () => {
      if (user.value.profile.last_name!==formState.last_name) {
        fb.db.collection("Users").doc(uid.value).update({
          'profile.last_name': formState.last_name.toLowerCase(),
        }).then(async () => {
          user.value.profile.last_name=formState.last_name.toLowerCase()
          await openToast('Your last name has been successfully updated.')
        }).catch(async (error) => {
          await openToast('There was an issue saving your last name.')
        })
      }
    }

    const updateBirthday =  (event) => {
      formState.birthday = event
      if (user.value.profile.birthday!==formState.birthday) {
        fb.db.collection("Users").doc(uid.value).update({
          'profile.birthday': formState.birthday
        }).then(async () => {
          user.value.profile.birthday=formState.birthday
          await openToast('Your birthday has been successfully updated.')
        }).catch(async (error) => {
          await openToast('There was an issue saving your birthday.')
        })
      }
    }

    const updateGender =  (event) => {
      formState.gender = event
      if (user.value.profile.gender!==formState.gender) {
        fb.db.collection("Users").doc(uid.value).update({
          'profile.gender': formState.gender
        }).then(async () => {
          user.value.profile.gender=formState.gender
          await openToast('Your gender has been successfully updated.')
        }).catch(async (error) => {
          await openToast('There was an issue saving your gender.')
        })
      }
    }

    const updateCity =  () => {
      if (user.value.profile.city!==formState.city) {
        fb.db.collection("Users").doc(uid.value).update({
          'profile.city': formState.city
        }).then(async () => {
          user.value.profile.city=formState.city
          await openToast('Your city has been successfully updated.')
        }).catch(async (error) => {
          await openToast('There was an issue saving your city.')
        })
      }
    }

    const updateCountry =  (event) => {
      formState.country = event
      if (user.value.profile.country!==formState.country) {
        fb.db.collection("Users").doc(uid.value).update({
          'profile.country': formState.country
        }).then(async () => {
          user.value.profile.country=formState.country
          await openToast('Your country has been successfully updated.')
        }).catch(async (error) => {
          await openToast('There was an issue saving your country.')
        })
      }
    }

    const logOut = () => {
      const auth = getAuth();
      signOut(auth).then(() => {
        causes.value = null
        uid.value = null
        user.value = null
        router.push('/')
      }).catch((error) => {
        console.log("Log out error",error)
      });

    }

    watchEffect( () => {
      if (user && user.value) {
        formState.username = user.value.profile.username ? user.value.profile.username : ''
        formState.email = user.value.profile.email ? user.value.profile.email : null
        formState.phone = user.value.profile.phone_number
        formState.first_name = user.value.profile.first_name ? user.value.profile.first_name : null
        formState.last_name = user.value.profile.last_name ? user.value.profile.last_name : null
        formState.birthday = user.value.profile.birthday ? user.value.profile.birthday : null
        formState.gender = user.value.profile.gender ? user.value.profile.gender : null
        formState.city = user.value.profile.city ? user.value.profile.city : null
        formState.country = user.value.profile.country ? user.value.profile.country : null

        formState.isLoading = false
      }
    })



    return {
      user,
      formState,

      moment,

      validateUsername, 
      updateUsername,
      updateEmailAddress,
      updateFirstName,
      updateLastName,
      updateBirthday,
      updateGender,
      updateCity,
      updateCountry,
      logOut
    };
  },

}
</script>
<style scoped>

.profile-section { margin: 10% 0; }
.profile-section ion-col { text-align:center; }

.profile-photo {
  display:block;
  width:99px;
  height:99px;
  margin:0 auto;
  border:3px solid #CCD57E;
  background:#9F9F9F;
  border-radius:100%
}

.profile-photo.merchant {
  border: 2px solid #004F7C;
}

.profile-photo img { border-radius:100%;}

.form { padding: 0 7%; }

.settings-section {
  padding:3vh 0 0;
}

ion-item {
  border:1px solid #ACACAC;
  border-radius: 25px;
}

ion-input, ion-select {
  border: none;
}

ion-label {
  font-size:13px !important;
  color:#ACACAC !important;
}

ion-input {
  font-size:14px !important;
  color:#0f0f0f !important;
}

</style>
