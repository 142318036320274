import { modalController  } from '@ionic/vue';

import { store } from '@/store/index'

class service {
  async triggerProfileBasicInfo() {
    if (!store.state.modal['profile-basic-info']) {
      store.state.modal['profile-basic-info'] = await modalController.create({
        component: require('@/components/modals/Profile/BasicInfo.vue').default,
        swipeToClose: false,
        backdropDismiss: false,
        showBackdrop: false
      }).catch(error => {
        console.log('triggerProfileBasicInfo', error)
      })

      return store.state.modal['profile-basic-info'].present();
    } else {
      store.state.modal['profile-basic-info'].dismiss()
      delete store.state.modal['profile-basic-info']
    }
  }

  async triggerEditProfile() {
      store.state.modal['edit-profile'] = await modalController.create({
        component: require('@/components/modals/Profile/EditProfile.vue').default,
        cssClass: 'three-quarter-modal',
        swipeToClose: true,
        backdropDismiss: true,
        showBackdrop: true
      }).catch(error => {
        console.log('triggerEditProfile', error)
      })

      return store.state.modal['edit-profile'].present();
  }

  async triggerMerchantAccountSettings() {
    if (!store.state.modal['merchant-account-settings']) {
      store.state.modal['merchant-account-settings'] = await modalController.create({
        component: require('@/components/modals/Merchant/AccountSettings.vue').default,
        cssClass: 'three-quarter-modal',
        swipeToClose: true,
        backdropDismiss: true,
        showBackdrop: true
      }).catch(error => {
        console.log('triggerMerchantAccountSettings', error)
      })

      return store.state.modal['merchant-account-settings'].present();
    }
  }

  async triggerViewAgreementsModal(data) {
    const modal = await modalController.create({
      component: require('@/components/modals/Merchant/PendingAgreements.vue').default,
      cssClass: 'three-quarter-modal',
      componentProps: {
        type: data
      },
      swipeToClose: true,
      backdropDismiss: true,
      showBackdrop: true
    }).catch(error => {
      console.log('triggerViewAgreementsModal', error)
    })

    return modal.present();
  }

  async triggerMerchantProfile() {
    if (!store.state.modal['merchant-profile-setup']) {
      store.state.modal['merchant-profile-setup'] = await modalController.create({
        component: require('@/components/modals/Authentication/BusinessProfileSetup.vue').default,
        swipeToClose: false,
        backdropDismiss: false,
        showBackdrop: false
      }).catch(error => {
        console.log('triggerMerchantProfile', error)
      })

      return store.state.modal['merchant-profile-setup'].present();
    } else {
      store.state.modal['merchant-profile-setup'].dismiss()
      delete store.state.modal['merchant-profile-setup']
    }
  }

  async triggerCreateNewTransaction() {
    const modal = await modalController.create({
      component: require('@/components/modals/Merchant/CreateTransaction.vue').default,
      cssClass: 'three-quarter-modal',
      swipeToClose: true,
      backdropDismiss: true,
      showBackdrop: true
    }).catch(error => {
      console.log('triggerMerchantProfile', error)
    })

    return modal.present();
    
  }

  async triggerFriendsModal() {
    if (!store.state.modal['add-a-friend']) {
      store.state.modal['add-a-friend'] = await modalController.create({
        component: require('@/components/modals/Friends').default,
        cssClass: 'three-quarter-modal',
        swipeToClose: true,
        backdropDismiss: true,
        showBackdrop: true
      }).catch(error => {
        console.log('triggerFriendsModal', error)
      })

      return store.state.modal['add-a-friend'].present();
    } else {
      store.state.modal['add-a-friend'].dismiss()
      delete store.state.modal['add-a-friend']
    }
  }

  async triggerCreateStaffModal() {
    if (!store.state.modal['create-staff']) {
      store.state.modal['create-staff'] = await modalController.create({
        component: require('@/components/modals/Merchant/Staff').default,
        cssClass: 'three-quarter-modal',
        swipeToClose: true,
        backdropDismiss: true,
        showBackdrop: true
      }).catch(error => {
        console.log('triggerCreateStaffModal', error)
      })

      return store.state.modal['create-staff'].present();
    } else {
      store.state.modal['create-staff'].dismiss()
      delete store.state.modal['create-staff']
    }
  }

  async triggerImpactPresetsModal() {
    if (!store.state.modal['impact-presets']) {
      store.state.modal['impact-presets'] = await modalController.create({
        component: require('@/components/modals/Merchant/ImpactPresets').default,
        cssClass: 'three-quarter-modal',
        swipeToClose: true,
        backdropDismiss: true,
        showBackdrop: true
      }).catch(error => {
        console.log('triggerImpactPresetsModal', error)
      })

      return store.state.modal['impact-presets'].present();
    } else {
      store.state.modal['impact-presets'].dismiss()
      delete store.state.modal['impact-presets']
    }
  }

  async triggerEditMerchantProfileModal() {
    store.state.modal['merchant-edit-profile'] = await modalController.create({
      component: require('@/components/modals/Merchant/EditProfile').default,
      cssClass: 'three-quarter-modal',
      swipeToClose: true,
      backdropDismiss: true,
      showBackdrop: true
    }).catch(error => {
      console.log('triggerEditMerchantProfileModal', error)
    })

    return store.state.modal['merchant-edit-profile'].present();
  }

  async triggerEditMerchantPasswordModal() {
    if (!store.state.modal['merchant-edit-password']) {
      store.state.modal['merchant-edit-password'] = await modalController.create({
        component: require('@/components/modals/Merchant/UpdatePassword').default,
        cssClass: 'three-quarter-modal',
        swipeToClose: true,
        backdropDismiss: true,
        showBackdrop: true
      }).catch(error => {
        console.log('triggerEditMerchantPasswordModal', error)
      })

      return store.state.modal['merchant-edit-password'].present();
    } else {
      store.state.modal['merchant-edit-password'].dismiss()
      delete store.state.modal['merchant-edit-password']
    }
  }  

  async triggerEditMerchantLocationModal() {
    if (!store.state.modal['merchant-update-location']) {
      store.state.modal['merchant-update-location'] = await modalController.create({
        component: require('@/components/modals/Merchant/UpdateLocation').default,
        cssClass: 'three-quarter-modal',
        swipeToClose: true,
        backdropDismiss: true,
        showBackdrop: true
      }).catch(error => {
        console.log('triggerEditMerchantPasswordModal', error)
      })

      return store.state.modal['merchant-update-location'].present();
    } else {
      store.state.modal['merchant-update-location'].dismiss()
      delete store.state.modal['merchant-update-location']
    }
  }  

}

export const modalService = new service()