<template>
  <ion-content>
      <ion-row class="ion-text-center">
        <ion-col size="12" class="ixkdhkjfn" style="font-size:18px;">
          Link to business <span class="red-title" style="padding-left:1vw;font-size:28px;">account</span>
        </ion-col>
        <ion-col size="12" style="font-size:22px;padding:10% 0;">

        </ion-col>
        <ion-col size="12" style="padding:0 10% 5%;">
          <RoundButton :text="'Done'" :color="'#00777E'" :loading="false" />
        </ion-col>
        <ion-col size="12" style="font-size:14px;color:#929292;">
          <span @click="closePopover">Cancel</span>
        </ion-col>
      </ion-row>
  </ion-content>
</template>

<script>
/* Vue/Vuex */
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

/* Ionic */
import { IonPage, IonContent, IonRow, IonCol, IonInput } from '@ionic/vue';
import { popoverController  } from '@ionic/vue';

/* Components */
import RoundButton from '@/components/buttons/Solid';

/* Classes */
import { popoverService } from "@/classes/popovers"

export default  {
  name: 'intro',

  components: {
    IonPage, IonContent, IonRow, IonCol, IonInput,
    RoundButton
  },

  setup() {
    const { state } = useStore();
    const account = computed(() => state.transactions.account);
    const uid = computed(() => state.auth.uid);

    let formState = reactive({
      isLoading: true,
      error:null,
      user:''
    });

    const closePopover = async () => {
      return popoverController.dismiss()
    }


      
    return {
      formState,

      closePopover,
    };
  },

}
</script>
<style scoped>

ion-content {
  --padding-top: 3vh !important;
  --padding-bottom: 3vh !important;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

ion-row {
  padding: 0 3vw
}

ion-item {
  border:1px solid #ACACAC;
  border-radius: 25px;
  margin: 0 0 3vh;
}

ion-input {
  font-size:14px !important;
  color:#0f0f0f !important;
}



</style>
