<template>
  <ion-content>

    <ion-row class="ion-padding">
        <ion-col size="12" style="text-align:center;padding:5% 0 0;">
          <ion-img :src="require('@/assets/images/color-logo.png')" style="width:180px;margin:0 auto;" />
        </ion-col>
      </ion-row>
      
      <ion-row class="form ion-text-center">
        <ion-col size="12" class="ion-padding-bottom">
          <h4>Basic Info</h4>
          <div style="height:2px;width:30%;margin:0 auto;background:#CCD57E;"></div>
        </ion-col>
        <ion-col size="12">
          <ion-input inputmode="text" type="text" v-model="formState.first_name" placeholder="First Name" />
        </ion-col>
        <ion-col size="12">
          <ion-input inputmode="text" type="text" v-model="formState.last_name" placeholder="Last Name" />
        </ion-col>
        <ion-col size="12">
          <ion-input inputmode="text" type="text" v-model="formState.city" placeholder="City" />
        </ion-col>
        <ion-col size="12">
          <ion-select interface="popover" placeholder="Country" @ionChange="formState.country=$event.target.value">
            <ion-select-option value="cad">Canada</ion-select-option>
            <ion-select-option value="usa">United States</ion-select-option>
          </ion-select>
        </ion-col>

        <ion-col size="12" style="padding:5vh 20vw 0;">
          <RoundButton :text="'Complete'" :color="'#00777E'" :loading="formState.isLoading" @click="validateSubmission" />
        </ion-col>
      </ion-row>

  </ion-content>
</template>

<script>
/* Vue/Vuex */
import { useStore } from 'vuex';
import { computed, watchEffect, reactive } from 'vue';

/* Firebase */
import 'firebase/auth'
const fb = require('@/firebase/init')

/* Mixins */
import {openToast} from '@/mixins/helpers'

/* Ionic */
import { IonPage, IonContent, IonRow, IonCol, IonImg, IonInput, IonSelect, IonSelectOption } from '@ionic/vue';
import { modalController  } from '@ionic/vue';

/* Components */
import RoundButton from '@/components/buttons/Solid';

import { popoverService } from '@/classes/popovers';

export default  {
  name: 'intro',

  components: {
    IonPage, IonContent, IonRow, IonCol, IonImg, IonInput, IonSelect, IonSelectOption,
    RoundButton
  },

  setup() {
    const { state } = useStore();
    const uid = computed(() => state.auth.uid);
    const user = computed(() => state.auth.user);
    const tags = computed(() => state.auth.tags);

    let formState = reactive({
      isLoading: false,
      first_name:'',
      last_name:'',
      city:'',
      country: '',
    });

    const validateSubmission = async () => {
      formState.isLoading = true
      
      if (!formState.first_name) {
        await openToast('Please provide a first name.')
      } else if (!formState.last_name) {
        await openToast('Please provide a last name.')
      } else if (!formState.city) {
        await openToast('Please provide a city.')
      } else if (!formState.country) {
        await openToast('Please provide a country.')
      } else {
        completeProfile()
      }
      
      formState.isLoading = false
    }

    const completeProfile = async () => {
      await fb.db.collection("Users").doc(uid.value).update({
        'profile.first_name': formState.first_name.toLowerCase(),
        'profile.last_name': formState.last_name.toLowerCase(),
        'profile.city': formState.city.toLowerCase(),
        'profile.country': formState.country.toLowerCase(),
      }).then(() => {
        user.value.profile.first_name = formState.first_name.toLowerCase()
        user.value.profile.last_name = formState.last_name.toLowerCase()
        user.value.profile.city = formState.city.toLowerCase()
        user.value.profile.country = formState.country.toLowerCase()

        fb.db.collection("Users").doc(uid.value).collection("Tags").doc('profile-basic-info').set({'created':new Date()})
        fb.db.collection("Users").doc(uid.value).collection("Tags").doc('profile-complete').set({'created':new Date()})
        
        modalController.dismiss()
        popoverService.triggerWelcomeMessage()

      }).catch(async (error) => {
        console.log(error)
        await openToast('There wan an error updating your profile. Please try again.')
      })
    }

    watchEffect(() => {
      if (tags.value.includes('profile-started')) { return modalController.dismiss() }
    });


    return {
      uid,
      formState,

      validateSubmission
    };
  },

}
</script>
<style scoped>

.form {
  padding:0 5vw;
}

ion-select {
  --placeholder-color:#acacac;
  --placeholder-opacity:1
}

</style>
