<template>
  <ion-content>

    <ion-row class="ion-padding">
      <ion-col size="12" style="text-align:center;padding:5% 0 0;">
        <ion-img :src="require('@/assets/images/color-logo.png')" style="width:180px;margin:0 auto;" />
      </ion-col>
    </ion-row>
      
    <ion-row v-if="type==='terms and conditions'" class="form ion-text-center">
      <ion-col size="12" class="ion-padding-bottom">
        <h4>Terms & Conditions</h4>
        <div style="height:2px;width:30%;margin:0 auto;background:#CCD57E;"></div>
      </ion-col>
      <ion-col size="12">
      </ion-col>
      <ion-col size="12" style="padding:5vh 20vw 0;">
        <RoundButton :text="'Agree'" :color="'#00777E'" :loading="formState.isLoading" @click="acceptTermsAgreement" />
        <span style="display:block;color:#ACACAC;font-size:16px;padding:10% 0 0;" @click="closeModal">Skip for now</span>
      </ion-col>
    </ion-row>

    <ion-row v-else-if="type==='service agreement'" class="form ion-text-center">
      <ion-col size="12" class="ion-padding-bottom">
        <h4>Service Agreement</h4>
        <div style="height:2px;width:30%;margin:0 auto;background:#CCD57E;"></div>
      </ion-col>
      <ion-col size="12">
      </ion-col>
      <ion-col size="12" style="padding:5vh 20vw 0;">
        <RoundButton :text="'Agree'" :color="'#00777E'" :loading="formState.isLoading" @click="acceptServiceAgreement" />
        <span style="display:block;color:#ACACAC;font-size:16px;padding:10% 0 0;" @click="closeModal">Skip for now</span>
      </ion-col>
    </ion-row>

  </ion-content>
</template>

<script>
/* Vue/Vuex */
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

/* Firebase */
import 'firebase/auth'
const fb = require('@/firebase/init')

/* Ionic */
import { IonPage, IonContent, IonRow, IonCol, IonImg, IonInput, IonTextarea, IonSelect, IonSelectOption } from '@ionic/vue';
import { modalController  } from '@ionic/vue';

/* Components */
import RoundButton from '@/components/buttons/Solid';

/* Classes */
import { modalService } from "@/classes/modals"

export default  {
  name: 'intro',

  props: {
    type: {
      type: String,
      default: null
    }
  },

  components: {
    IonPage, IonContent, IonRow, IonCol, IonImg, IonInput, IonTextarea, IonSelect, IonSelectOption,
    RoundButton
  },

  setup() {
    const { state } = useStore();
    const uid = computed(() => state.auth.uid);
    const tags = computed(() => state.auth.tags);

    let formState = reactive({
      isLoading: false,
    });

    const closeModal = () => {
      modalController.dismiss();
    };

    const acceptTermsAgreement = async () => {
      formState.isLoading = true
      
      fb.db.collection("Users").doc(uid.value).collection('Tags').doc('terms-conditions-accepted').set({'created':new Date()}).then(() => {
        tags.value.push('terms-conditions-accepted')
        return closeModal()
      })
      
      formState.isLoading = false
    }

    const acceptServiceAgreement = async () => {
      formState.isLoading = true
      
      await fb.db.collection("Users").doc(uid.value).collection('Tags').doc('service-agreement-accepted').set({'created':new Date()}).then(() => {
        tags.value.push('service-agreement-accepted')
        return closeModal()
      })
    }

    return {
      formState,

      closeModal,
      acceptTermsAgreement,
      acceptServiceAgreement,
    };
  },

}
</script>
<style scoped>

.form ion-col {
  padding:1.5vh 10vw
}


ion-select {
  --placeholder-color:#acacac;
  --placeholder-opacity:1
}

ion-textarea {
  min-height:25vh
}

.search-results {
  background:#ffffff;
  border-radius:8px;
  border: 1px solid #c4c4c4;
  box-shadow: 2px 3px 8px rgba(0,0,0,0.3);
  max-height:350px;
  overflow-y:scroll
}
.search-results ul {
  padding: 0;
  text-align:left
}
.search-results li {
  padding:10px 20px;
  list-style-type: none;
  font-size:14px;
}
.search-results li:hover {
  background:#f5f5f5;
  cursor: pointer;
}

.tag {
  background:#CED389;
  color: #ffffff;
  font-size:12px;
  border-radius: 25px;
  padding: 10px 20px
}
</style>
