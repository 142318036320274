export default {
    //Load User Profile
    loadUserBegin(state) {
      state.loading = true;
      console.log("Load Beginning",state.loading)
    },

    async loadUser(state, data) {
      state.uid = data.id
      state.user = await data.data()
    },

    loadUserSuccess(state) {
      state.loading = false;
      console.log("Load Success",state.loading)
    },

    loadUserErr(state, err) {
      state.error = err;
      state.loading = false;
    },

    loadUserNotificationsCountsSuccess(state, data) {
      state.notificationsCount = data;
    },

    loadUserNotificationsSuccess(state, data) {
      state.notifications = data;
      state.loading = false;
    },

    loadUserFriendsSuccess(state, data) {
      state.friends = data;
      state.loading = false;
    },

  };
