<template>
  <ion-content class="ion-padding">
    
    <ion-row style="padding:0 0 5%;text-align:center">
      <ion-col size="12">
        <span class="profile-photo">
          <img v-if="user.profile.profile_photo" :src="user.profile.profile_photo">
        </span>
      </ion-col>
      <ion-col size="12">
        <span style="font-weight:600;color:#004F7C;font-size:13px;text-transform:uppercase;">{{user.profile.company_name}}</span>
      </ion-col>
      <ion-col size="12" style="padding:10vh 0 0;">
        <ion-item button @click="editProfile()" detail>
          <ion-label>
            Edit Profile
          </ion-label>
        </ion-item>
        <ion-item button @click="updatePassword()" detail>
          <ion-label>
            Update Password
          </ion-label>
        </ion-item>
        <ion-item button @click="updateLocation()" detail>
          <ion-label>
            Update Location
          </ion-label>
        </ion-item>
        <ion-item button @click="companyDetails()" detail>
          <ion-label>
            Company Details
          </ion-label>
        </ion-item>
        <ion-item button @click="searchTags()" detail>
          <ion-label>
            Search Tags
          </ion-label>
        </ion-item>
        <ion-item button @click="staffMembers()" detail>
          <ion-label>
            Staff Members
          </ion-label>
        </ion-item>
        <ion-item button @click="impactPresets()" detail>
          <ion-label>
            Impact Presets
          </ion-label>
        </ion-item>
      </ion-col>
    </ion-row> 

    <ion-row class="settings-section">
      <ion-col size="12">
        <OutlinedButton :text="'Log Out'" :textcolor="'#00777E'" :color="'#00777E'" style="width:50%;margin:1vh auto 0" @click="logOut()" />  
      </ion-col>
    </ion-row>

  </ion-content>
</template>

<script>
/* Vue/Vuex */
import { useStore } from 'vuex';
import { computed, onUnmounted } from 'vue';

/* Ionic */
import { IonContent, IonRow, IonCol, IonInput, IonLabel, IonItem } from '@ionic/vue';
import { modalController  } from '@ionic/vue'; 
import { useRouter } from 'vue-router'

/* Components */
import OutlinedButton from '@/components/buttons/Outlined.vue'
import Icon from '@/components/widgets/Icon.vue'

/* Plugins */
import { getAuth, signOut } from "firebase/auth";
import firebase from 'firebase/app'
import 'firebase/auth'

/* Classes */
import { modalService } from "@/classes/modals"

export default  {
  name: 'Settings',

  components: {
    IonContent, IonRow, IonCol, IonInput, IonLabel, IonItem,
    OutlinedButton, Icon
  },

  setup() {
    const { state, dispatch } = useStore();
    const router = useRouter()
    const user = computed(() => state.auth.user);
    const modals = computed(() => state.modal);

    onUnmounted(() => {
      delete modals.value['merchant-account-settings']
    })

    const closeModal = async () => {
      modalController.dismiss();
    };

    const editProfile = async () => {
      closeModal()
      modalService.triggerEditMerchantProfileModal()
    };

    const updatePassword = async () => {
      closeModal()
      modalService.triggerEditMerchantPasswordModal()
    };

    const updateLocation = async () => {
      closeModal()
      modalService.triggerEditMerchantLocationModal()
    };

    const companyDetails = async () => {
      closeModal()
      modalService.triggerEditMerchantDetailsModal()
    };

    const searchTags = async () => {
      closeModal()
      modalService.triggerEditMerchantSearchTagsModal()
    };

    const staffMembers = async () => {
      closeModal()
      modalService.triggerCreateStaffModal()
    };

    const impactPresets = async () => {
      closeModal()
      modalService.triggerImpactPresetsModal()
    };


    const logOut = () => {
      const auth = getAuth();
      signOut(auth).then(() => {
        causes.value = null
        uid.value = null
        user.value = null
        router.push('/')
      }).catch((error) => {
        console.log("Log out error",error)
      });

    }

    
    return { 
      user,

      closeModal,
      logOut,
      staffMembers,
      impactPresets,
      editProfile,
      updatePassword,
      updateLocation,
      companyDetails,
      searchTags
    };
  },

}

</script>

<style scoped>

.profile-photo {
  display:block;
  width:99px;
  height:99px;
  margin:0 auto;
  border:3px solid #004F7C;
  background:#9F9F9F;
  border-radius:100%
}

.profile-photo img { border-radius:100%;}

</style>