<template>
  <ion-row class="ion-align-items-end" v-bind:class="{selected: item.id == transaction.cause, static: !selectable}" @click="selectImpact">
    <ion-col size="12" v-if="item.type=='trees'">
      <svg width="35.989" height="42.593" viewBox="0 0 35.989 42.593" class="fill-path">
        <g transform="translate(17.958 21.294)">
          <g transform="translate(-18.016 -21.299)">
            <path d="M24.953,31.425A11.147,11.147,0,0,1,18,29.012,11.182,11.182,0,1,1,7.31,9.594,11.182,11.182,0,0,1,29.5,10.02a11.182,11.182,0,0,1-4.544,21.406m-6.922-4.473a.887.887,0,0,1,.6.248,9.407,9.407,0,1,0,9.727-15.726.887.887,0,0,1-.568-.781A9.407,9.407,0,0,0,9.05,10.339a.887.887,0,0,1-.6.781,9.407,9.407,0,1,0,8.981,16.045A.887.887,0,0,1,18,26.953" transform="translate(-0.104 -0.009)" fill="#004f7c"/>
            <path d="M48.387,60.981a.887.887,0,0,1-.887-.887V29.387a.887.887,0,1,1,1.775,0V60.094a.887.887,0,0,1-.887.887" transform="translate(-30.638 -18.383)" fill="#004f7c"/>
            <path d="M49.442,48.95a.887.887,0,0,1-.39-1.668l5.964-2.946a.889.889,0,1,1,.781,1.6l-5.964,2.911-.39.106" transform="translate(-31.338 -28.539)" fill="#004f7c"/>
            <path d="M35.448,59.683l-.39-.071-7.064-3.479a.889.889,0,1,1,.781-1.6l7.064,3.479a.887.887,0,0,1-.39,1.668" transform="translate(-17.735 -35.118)" fill="#004f7c"/>
          </g>
        </g>
      </svg>
    </ion-col>
    <ion-col size="12" v-if="item.type=='bees'">
      <svg width="47.568" height="43.269" viewBox="0 0 47.568 43.269" class="fill-path">
        <defs>
          <clipPath id="beehive-clip-path">
          <rect width="47.568" height="43.269" fill="none"/>
          </clipPath>
        </defs>
        <g clip-path="url(#beehive-clip-path)">
          <path d="M81.25,50.268c-4.65,0-10.117-4.65-12.105-7.064-5.715-6.922-6.461-9.23-6.532-9.478l-.213-.71.71-.177c.639-.142,15.8-3.763,22.045,1.739,2.059,1.81,2.946,4.366,2.591,7.632-.461,4.4-2.059,7.029-4.721,7.81a6.07,6.07,0,0,1-1.739.248m-17-16.258a53.127,53.127,0,0,0,5.964,8.271c2.236,2.733,8.378,7.561,12.354,6.39,2.059-.6,3.3-2.84,3.727-6.6.319-2.8-.39-4.934-2.094-6.425-4.934-4.331-16.684-2.272-19.915-1.6" transform="translate(-40.249 -20.343)" fill="#004f7c"/>
          <path d="M7.221,55.929H6.688C3.919,55.645,1.86,53.373.547,49.184c-.958-3.195-.6-5.893,1.065-8.058,2.165-2.8,6.39-4.65,12.638-5.5a53.142,53.142,0,0,1,8.626-.461h.745l-.071.745c-.035.248-.319,2.7-4.579,10.579-1.633,3.053-6.78,9.407-11.715,9.407M21.562,36.547c-3.763,0-14.98.426-18.814,5.431-1.42,1.81-1.668,4.011-.852,6.745,1.136,3.621,2.8,5.538,4.934,5.751,4.118.426,9.194-5.538,10.863-8.662a50.692,50.692,0,0,0,4.224-9.265h-.355" transform="translate(-0.014 -22.667)" fill="#004f7c"/>
          <path d="M53.292,66.622c-.71,0-.923-.816-1.7-4.366-.248-1.1-.568-2.556-.781-3.159-4.224-.284-7.916-3.479-9.3-8.058l-.106-.39.248-.284a21.938,21.938,0,0,0,2.769-3.834c3.3-6.141,4.153-8.839,4.366-9.727l.106-.461.532-.071h.319l.568-.071.213.461c.39.816,1.7,3.337,6.177,8.7a22.755,22.755,0,0,0,4.118,3.692l.355.248-.071.426A10.821,10.821,0,0,1,54.428,58.6c-.071.639-.106,2.2-.106,3.3-.035,3.869-.071,4.579-.887,4.721h-.142M43,51c1.349,3.94,4.615,6.638,8.236,6.709.745,0,.887.568,1.7,4.118.035-3.656.071-4.26.745-4.473a9.325,9.325,0,0,0,5.964-7.419A23.89,23.89,0,0,1,55.6,46.211a62.057,62.057,0,0,1-5.786-7.952,56.263,56.263,0,0,1-4.153,8.91A22.968,22.968,0,0,1,43,50.967" transform="translate(-26.704 -23.349)" fill="#004f7c"/>
          <path d="M47.99,23.336l-.213-.39a5.644,5.644,0,0,1,.6-6.248,6.035,6.035,0,0,1,3.976-2.2,5.893,5.893,0,0,1,6.638,4.97,5.609,5.609,0,0,1-.213,2.378l-.142.426-.461.071c-2.7.355-4.615.816-4.615.816l-.319.071h-.071c-.035,0-2.023-.071-4.757.071Zm5.112-7.49h-.6a4.615,4.615,0,0,0-3.053,1.7,4.26,4.26,0,0,0-.923,3.23,4.118,4.118,0,0,0,.284,1.029c2.307-.142,3.976-.071,4.331-.071h.106c.248-.071,1.881-.426,4.26-.781a4.189,4.189,0,0,0,.035-1.349A4.437,4.437,0,0,0,53.1,15.846" transform="translate(-30.383 -9.314)" fill="#004f7c"/>
          <path d="M51.252,86.721,50.4,85.585a14.811,14.811,0,0,1,9.372-2.414,22.08,22.08,0,0,1,4.97.745l-.39,1.349c-.071-.035-7.916-2.272-13.1,1.455" transform="translate(-32.509 -53.636)" fill="#004f7c"/>
          <path d="M44.274,72.167,43.6,70.924c10.721-5.964,18-1.739,18.317-1.562l-.745,1.207c-.071-.035-6.922-3.976-16.9,1.6" transform="translate(-28.123 -43.697)" fill="#004f7c"/>
          <path d="M53.116,57.238,52.3,56.066c5.431-3.763,9.514-1.739,9.691-1.668l-.639,1.278.319-.639-.319.639c-.142-.071-3.55-1.7-8.236,1.562" transform="translate(-33.734 -34.696)" fill="#004f7c"/>
          <path d="M68.741,6.668H68.6a.71.71,0,0,1-.532-.852A8.129,8.129,0,0,1,73.072.03a.713.713,0,0,1,.461,1.349,6.674,6.674,0,0,0-4.082,4.757.71.71,0,0,1-.71.532" transform="translate(-43.892 0.006)" fill="#004f7c"/>
          <path d="M44.956,9.978a.71.71,0,0,1-.674-.426,6.674,6.674,0,0,0-4.792-4.047.718.718,0,1,1,.213-1.42,8.129,8.129,0,0,1,5.928,4.9.71.71,0,0,1-.39.923h-.284" transform="translate(-25.076 -2.63)" fill="#004f7c"/>
        </g>
      </svg>
    </ion-col>
    <ion-col size="12" v-if="item.type=='fish'">
      <svg width="35.428" height="36.503" viewBox="0 0 35.428 36.503">
        <defs>
          <clipPath id="clip-path">
            <rect width="35.428" height="36.503" fill="none"/>
          </clipPath>
        </defs>
        <g  transform="translate(17.714 18.252)">
          <g transform="translate(-17.714 -18.252)" clip-path="url(#clip-path)">
            <path d="M31.026,30.144h-.213a.887.887,0,0,1-.674-.852c-.035-.355-.745-9.194-13.844-10.153C1.7,18.039.036,10.584-.035,10.265a.887.887,0,0,1,.106-.639c.071-.106,6.6-10.721,17.856-8.555A21.6,21.6,0,0,1,33.121,12.75c1.7,3.763,2.875,9.727-1.313,16.933a.887.887,0,0,1-.781.426M1.811,10.229a7.7,7.7,0,0,0,2.2,2.982c1.846,1.6,5.5,3.621,12.425,4.153,10.224.781,13.7,6.141,14.874,9.372A15.51,15.51,0,0,0,31.488,13.5,19.714,19.714,0,0,0,17.608,2.81C8.485,1.071,2.947,8.525,1.811,10.229m-.958-.142Z" transform="translate(0.035 -0.503)" fill="#8e8e8e"/>
            <path d="M54.537,85.967H54.43a4.153,4.153,0,0,1-2.8-1.846c-.923-1.455-.923-3.514-.071-6.106a.887.887,0,0,1,1.278-.5c.071.035,6.354,3.443,10.295-.213l.319-.319a.887.887,0,0,1,1.491.887,6.78,6.78,0,0,1-.284.71,12.741,12.741,0,0,1-9.975,7.384h-.142m-1.562-6.39a4.572,4.572,0,0,0,.177,3.585,2.343,2.343,0,0,0,1.42,1.029,11.3,11.3,0,0,0,6.958-3.869,10.153,10.153,0,0,1-5.289.248,15.9,15.9,0,0,1-3.266-1.029" transform="translate(-32.847 -49.474)" fill="#8e8e8e"/>
            <path d="M64.875,7.682a.887.887,0,0,1-.639-.284A18.14,18.14,0,0,0,57.6,3.387a.887.887,0,0,1-.284-1.526c.071-.035,6.532-5.076,14.554,1.952a.887.887,0,0,1-.674,1.526c-1.242-.106-4.9-.035-5.5,1.739a.887.887,0,0,1-.639.6h-.213M59.976,2.393a20.376,20.376,0,0,1,4.686,2.982,5.671,5.671,0,0,1,3.656-1.7l.39-.071a9.859,9.859,0,0,0-6.816-1.7,8.413,8.413,0,0,0-1.917.5" transform="translate(-36.76 -0.05)" fill="#8e8e8e"/>
            <path d="M37.6,19.034a.887.887,0,0,1-.39-1.7,7.008,7.008,0,0,0,4.118-4.437C42.61,8.349,38.989,2.74,38.954,2.669a.9.9,0,1,1,1.491-.994c.177.248,4.118,6.354,2.627,11.679A8.713,8.713,0,0,1,38,18.927L37.6,19" transform="translate(-23.654 -0.823)" fill="#8e8e8e"/>
            <path d="M32.5,24.065a1.065,1.065,0,1,1,1.065,1.065A1.065,1.065,0,0,1,32.5,24.065" transform="translate(-20.963 -14.835)" fill="#8e8e8e"/>
          </g>
        </g>
      </svg>

    </ion-col>
    <ion-col size="12" v-if="item.type=='earths'">
      <svg width="45.3" height="32.125" viewBox="0 0 45.3 32.125">
        <path d="M8.875,38.6H.994a.994.994,0,1,0,0,1.988H8.875A15.477,15.477,0,0,1,24.352,56.03a.994.994,0,0,0,1.988,0A17.465,17.465,0,0,0,8.875,38.6" transform="translate(0.003 -24.899)" fill="#004f7c"/>
        <path d="M8.875,60.8H.994a.994.994,0,1,0,0,1.988H8.875a7.6,7.6,0,0,1,7.6,7.561.994.994,0,0,0,1.988,0A9.549,9.549,0,0,0,8.875,60.8" transform="translate(0.003 -39.219)" fill="#004f7c"/>
        <path d="M27.258,10.4l-1.029.781-.958.852L23.424,10.4A25.027,25.027,0,0,0,12.065,6,5.644,5.644,0,0,0,13.485.891.994.994,0,0,0,12.7.145,5.644,5.644,0,0,0,7.485,1.672a5.715,5.715,0,0,0-.674.816,5.751,5.751,0,0,0-.674-.816A5.644,5.644,0,0,0,.883.145.994.994,0,0,0,.137.891,5.644,5.644,0,0,0,1.344,5.825H1.025A1.029,1.029,0,0,0,0,6.748.994.994,0,0,0,.989,7.778H8.87A23.358,23.358,0,0,1,32.228,31.136a.994.994,0,0,0,1.988-.036,7.6,7.6,0,0,1,7.561-7.561H43.2a1.278,1.278,0,0,0,1.207-.958h0a.816.816,0,0,0-.781-.994H41.742a9.549,9.549,0,0,0-8.058,4.473,25.027,25.027,0,0,0-2.236-6.425,15.335,15.335,0,0,1,10.33-3.976h2.485a1.029,1.029,0,0,0,1.029-1.065h0a.923.923,0,0,0-.923-.887H41.777a17.288,17.288,0,0,0-11.324,4.189,25.524,25.524,0,0,0-3.656-4.65,23.287,23.287,0,0,1,14.98-5.431h1.1a.923.923,0,0,0,.852-1.207h0a.781.781,0,0,0-.745-.532l-5.4.142A25.2,25.2,0,0,0,27.258,10.4M5.817,5.719v.106H5.6A3.692,3.692,0,0,1,1.983,1.991,3.692,3.692,0,0,1,5.817,5.577q0,.053,0,.106m4.721-.958A3.656,3.656,0,0,1,7.982,5.79H7.769V5.577a3.692,3.692,0,0,1,3.869-3.585A3.656,3.656,0,0,1,10.574,4.76" transform="translate(0.008 -0.005)" fill="#004f7c"/>
      </svg>
    </ion-col>
    <ion-col size="12">
      <span style="font-size:13px;font-weight:600;">{{item.impact}} {{item.measurement}}</span>
    </ion-col>
  </ion-row>
</template>

<script>
/* Vue/Vuex */
import { useStore } from 'vuex';
import { computed } from 'vue';

/* Ionic */
import { IonRow, IonCol } from '@ionic/vue'

export default {
  components: {
    IonRow, IonCol
  },

  props: {
    item: {
      default: null,
      type: Object
    },
    selectable: {
      default: false,
      type: Boolean
    }
  },

  setup(props) {
    const { state } = useStore();
    const transaction = computed(() => state.transactions.transaction);

    const selectImpact = async () => {
      if (props.selectable) {
        if (state.transactions.transaction.cause===props.item.id) {
          state.transactions.transaction.cause = null
        } else {
          state.transactions.transaction.cause = props.item.id
        }
      }
    }
      
    return {
      transaction,

      selectImpact
    };
  },
}
</script>

<style scoped>
ion-row {
  height:91px;
}

ion-col {
  padding: 0 !important;
}

ion-row.static svg path,
ion-row.selected svg path {
  fill: #004f7c !important
}

ion-row.static span,
ion-row.selected span {
  color: #004f7c !important
}

ion-row svg path {
  fill: #8E8E8E
}

ion-row span {
  color: #8E8E8E
}


</style>
