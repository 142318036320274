import { popoverController  } from '@ionic/vue';

class service {
  async triggerWelcomeMessage() {
    const popover = await popoverController.create({
      component: require('@/components/popovers/Welcome.vue').default,
      cssClass: 'full-popover',
      showBackdrop: true,
      backdropDismiss: false,
      animated: true,
      keyboardClose: false,
    })
    await popover.present();
  }

  async triggerBusinessSignupInfo() {
    const popover = await popoverController.create({
      component: require('@/components/popovers/Business.vue').default,
      cssClass: 'full-popover',
      showBackdrop: true,
      backdropDismiss: true,
      animated: true,
      keyboardClose: true,
    })
    await popover.present();
  }

  async triggerPendingAgreement(data) {
    const popover = await popoverController.create({
      component: require('@/components/popovers/PendingAgreement.vue').default,
      cssClass: 'full-popover',
      componentProps: {
        type: data
      },
      showBackdrop: true,
      backdropDismiss: true,
      animated: true,
      keyboardClose: true,
    })
    await popover.present();
  }  

  async triggerLinkTransaction() {
    const popover = await popoverController.create({
      component: require('@/components/popovers/LinkTransactionToUser.vue').default,
      cssClass: 'full-popover',
      showBackdrop: true,
      backdropDismiss: true,
      animated: true,
      keyboardClose: true,
    })

    await popover.present();
  }  

  async triggerLinkToBusiness() {
    const popover = await popoverController.create({
      component: require('@/components/popovers/LinkTransactionToBusiness.vue').default,
      cssClass: 'full-popover',
      showBackdrop: true,
      backdropDismiss: false,
      animated: true,
      keyboardClose: false,
    })

    await popover.present();
  }  

  async triggerAddNewImpact() {
    const popover = await popoverController.create({
      component: require('@/components/popovers/AddNewImpact.vue').default,
      cssClass: 'full-popover',
      showBackdrop: true,
      backdropDismiss: true,
      animated: true,
      keyboardClose: true,
    })

    await popover.present();
  }  

}

export const popoverService = new service()