export default {
    loadCausesBegin(state) {
      state.loading = true;
    },

    loadCausesSuccess(state, data) {
      state.loading = false;
      state.causes = data
    },

    loadCausesErr(state, err) {
      state.error = err;
      state.loading = false;
    },

  };
