<template>
  <div v-if="!hashtag">
  <RoundButton v-if="type=='notification' && !friend && !data.isFollowing" :text="'Follow Back'" size="small" :fill="'solid'" :textcolor="'#ffffff'" @click="followUser" />
  <RoundButton v-else-if="!friend && !data.isFollowing" :text="'Follow'" :fill="'solid'" :textcolor="'#ffffff'" @click="followUser" />
  <RoundButton v-else :text="'Unfollow'" :fill="'solid'" :color="'#c7c7c7'" :textcolor="'#ffffff'"  @click="unfollowUser" />
  </div>
  <div v-else>
    <RoundButton v-if="!friend && !data.isFollowing" :text="'Follow'" :color="'#DC8D83'" :fill="'solid'" :textcolor="'#ffffff'" @click="followHashtag" />
    <RoundButton v-else :text="'Unfollow'" :fill="'solid'" :color="'##DC8D83'" :textcolor="'#ffffff'"  @click="unfollowHashtag" :style="'opacity:0.6'" />
  </div>
</template>

<script>
/* Vue/Vuex */
import { useStore } from 'vuex';
import { computed, reactive, watchEffect } from 'vue';

/*Components */
import RoundButton from '@/components/buttons/Solid';

/* Plugins */
const fb = require('@/firebase/init')

export default {
  components: {
    RoundButton
  },

  props: {
    uid: { 
      default: null, 
      type:String 
    },
    friend: {
      default: false,
      type: Boolean
    },
    hashtag: {
      default: null,
      type: String
    },
    type: {
      default: '',
      type: String
    }
  },

  setup(props) {
    const { state, dispatch } = useStore();
    const loggedInUid = computed(() => state.auth.uid);

    const data = reactive({
      isFollowing: false
    });

    watchEffect(() => {
      if (props.friend) {
        data.isFollowing = props.friend
      }
    });

    const followUser = () => {
      data.isFollowing = true
      dispatch("followUser", props.uid)
    };

    const unfollowUser = () => {
      data.isFollowing = false
      dispatch("unfollowUser", props.uid)
    };

    const followHashtag = () => {
      data.isFollowing = true
      fb.db.collection("Hashtags").doc(props.hashtag).collection("Followers").doc(loggedInUid.value).set({'created':new Date})
    };

    const unfollowHashtag = () => {
      data.isFollowing = false
      fb.db.collection("Hashtags").doc(props.hashtag).collection("Followers").doc(loggedInUid.value).delete()
    };

    return {
      loggedInUid,
      data,

      followUser,
      unfollowUser,
      followHashtag,
      unfollowHashtag
    };
  },

}
</script>