export default {
    loadTransactionBegin(state) {
      state.loading = true;
    },

    loadTransactionSuccess(state) {
      state.loading = false;
    },

    loadTransactionErr(state, err) {
      state.error = err;
      state.loading = false;
    },

  };
