import mutations from './mutations';

const fb = require('@/firebase/init')

const state = () => ({
  loading: true,
  error: null,
  account: {
    business: false,
    phone: null,
    email: null,
  },
  transactions: {},
  transaction: {
    cause: null,
    format: null,
    cost: 0,
    impacts: []
  },
});

const actions = {

};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
