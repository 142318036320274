<template>
  <ion-content v-if="formState.step===1">
    <ion-row class="ion-text-center">
      <ion-col size="12" class="ixkdhkjfn" style="font-size:18px;">
        Add new <span class="red-title" style="padding-left:1vw;font-size:28px;">impact</span>
      </ion-col>
      <ion-col size="12" style="padding:10% 0;">
        <span style="display:block;font-size:12px;color:#929292;padding:0 0 3%">Select A Cause</span>
        <Causes v-if="formState.causes.length>0" :items="formState.causes" :selectable="true" />
      </ion-col>
      <ion-col size="12">
        <span style="display:block;font-size:12px;color:#929292;padding:0 0 3%">Select A Format</span>
        <Formats />
      </ion-col>
      <ion-col size="12" style="padding:10% 10% 5%;">
        <RoundButton v-if="transaction.cause && transaction.format" :text="'Next'" :color="'#00777E'" :loading="false" @click="formState.step=2" />
        <RoundButton v-else  :text="'Next'" :color="'#707070'" :loading="false" />
      </ion-col>
      <ion-col size="12" style="font-size:14px;color:#929292;">
        <span @click="closePopover">Close</span>
      </ion-col>
    </ion-row>
  </ion-content>
  <ion-content v-if="formState.step===2">
    <ion-row class="ion-text-center">
      <ion-col size="12" class="ixkdhkjfn" style="font-size:18px;">
        Add new <span class="red-title" style="padding-left:1vw;font-size:28px;">impact</span>
      </ion-col>
      <ion-col size="12" style="padding:10% 0 0;">
        <Impact :type="formState.cause.type" />
      </ion-col>
      <ion-col size="12" class="ixkdhkjfn">
        {{formState.cause.long_name}}
      </ion-col>
      <ion-col size="12" style="padding:5% 20%" v-if="transaction.format==='hourly'">
        <ion-input inputmode="number" type="number" v-model="formState.amount" :placeholder="formState.cause.name+' per hour'" style="border-color:#004F7C;color:#004F7C;font-weight:600" @keyup="calculateTotal" />
        <ion-input inputmode="number" type="number" v-model="formState.rate" placeholder="total hours" style="margin:10% 0 5%;border-color:#E19E96;color:#E19E96;font-weight:600" @keyup="calculateTotal" />
        <span style="display:block;color:#E19E96;font-size:13px;">${{formState.total * formState.cause.cost}} Impact Cost</span>
      </ion-col>
      <ion-col size="12" style="padding:5% 20%" v-else-if="transaction.format==='percentage'">
        <ion-input inputmode="number" type="number" v-model="formState.amount" placeholder="order amount" style="border-color:#004F7C;color:#004F7C;font-weight:600" @keyup="calculateTotal" />
        <ion-input inputmode="number" type="number" v-model="formState.rate" placeholder="percentage" style="margin:10% 0 5%;border-color:#E19E96;color:#E19E96;font-weight:600" @keyup="calculateTotal" />
        <span style="display:block;color:#E19E96;font-size:13px;">${{formState.total ? formState.total.toFixed(2) : 0}} Impact Cost</span>
      </ion-col>
      <ion-col size="12" style="padding:5% 20%" v-else-if="transaction.format==='flat rate'">
        <ion-input inputmode="number" type="number" v-model="formState.amount" placeholder="amount of impact" style="border-color:#004F7C;color:#004F7C;font-weight:600" @keyup="calculateTotal" />
        <span style="display:block;color:#E19E96;font-size:13px;">${{(formState.amount * formState.cause.cost).toFixed(2)}} Impact Cost</span>
      </ion-col>
      <ion-col size="12" style="padding:5% 10%;">
        <RoundButton v-if="transaction.format==='hourly' && Number(formState.total)>0" :text="'Confirm ' + formState.total + ' ' + formState.cause.measurement" :color="'#00777E'" :loading="false" @click="saveImpact" />
        <RoundButton v-else-if="transaction.format==='percentage' && Number(formState.total)>0" :text="'Confirm ' + (formState.total/formState.cause.cost).toFixed(2) + ' ' + formState.cause.measurement" :color="'#00777E'" :loading="false" @click="saveImpact" />
        <RoundButton v-else-if="transaction.format==='flat rate' && Number(formState.amount)>0" :text="'Confirm ' + formState.total + ' ' + formState.cause.measurement" :color="'#00777E'" :loading="false" @click="saveImpact" />
        <RoundButton v-else  :text="'Confirm ' + ' 0 ' + formState.cause.measurement" :color="'#707070'" :loading="false" />
      </ion-col>
      <ion-col size="12" style="font-size:14px;color:#929292;">
        <span @click="formState.step=1">Back</span>
      </ion-col>
    </ion-row>
  </ion-content>
</template>

<script>
/* Vue/Vuex */
import { useStore } from 'vuex';
import { computed, onMounted, onUnmounted, watchEffect, reactive } from 'vue';

/* Ionic */
import { IonPage, IonContent, IonRow, IonCol, IonInput } from '@ionic/vue';
import { popoverController  } from '@ionic/vue';

/* Plugins */
const fb = require('@/firebase/init')

/* Components */
import Causes from '@/components/cards/Causes'
import Impact from '@/components/icons/Impact.vue'
import Formats from '@/components/cards/Formats'
import RoundButton from '@/components/buttons/Solid';

export default  {
  name: 'intro',

  components: {
    IonPage, IonContent, IonRow, IonCol, IonInput,
    Causes, Impact, Formats, RoundButton
  },

  setup() {
    const { state } = useStore();
    const format = computed(() => state.transactions.format);
    const transaction = computed(() => state.transactions.transaction);

    let formState = reactive({
      isLoading: true,
      step: 1,
      cause: null,
      causes: [],
      amount: null,
      rate: null,
      total: null
    });

    onMounted(async () => {
      const query = await fb.db.collection('Causes').where('status','==',true).get()
      query.forEach(doc => {
        formState.causes.push({id:doc.id,...doc.data()})
      })
    })

    onUnmounted(async () => {
      formState.amount=null
      formState.rate=null
      formState.total=null
    })

    watchEffect(async () => {
      if (transaction && transaction.value.cause) {
        const query = await await fb.db.collection('Causes').doc(transaction.value.cause).get()
        formState.cause = query.data()        
      }
    })

    const closePopover = async () => {
      return popoverController.dismiss()
    }

    const calculateTotal = async () => {
      if (formState.amount > 0 && formState.rate > 0) {
        if (transaction.value.format==='hourly') { formState.total = formState.amount * formState.rate }
        else if (transaction.value.format==='percentage') { formState.total = formState.amount * (formState.rate/100) }
      } else if (formState.amount>0 && transaction.value.format==='flat rate') {
        formState.total = Number(formState.amount)
      } else { formState.total=null}
    }

    const saveImpact = async () => {
      let string = ''

      if (transaction.value.format==='hourly') {
        string = formState.rate + " hours for " + (formState.amount * formState.rate) + " " + formState.cause.action
        transaction.value.cost += (formState.amount * formState.rate) * formState.cause.cost
      } else if (transaction.value.format==='percentage') {
        string = (formState.total/formState.cause.cost) + ' ' + formState.cause.action + " for " + formState.rate + "% of purchase"
        transaction.value.cost += formState.total
      } else if (transaction.value.format==='flat rate') {
        string = formState.total + ' ' + formState.cause.action + " for purchase"
        transaction.value.cost += formState.total * formState.cause.cost
      }
      
      transaction.value.impacts.push({
        id: transaction.value.cause, 
        cause:formState.cause,
        amount:Number(formState.amount),
        rate:Number(formState.rate),
        format:transaction.value.format,
        total:Number(formState.total),
        title:string
      })


      closePopover()
    }
      
    return {
      formState,
      transaction,

      closePopover,
      saveImpact,
      calculateTotal
    };
  },

}
</script>
<style scoped>

ion-content {
  --padding-top: 3vh !important;
  --padding-bottom: 3vh !important;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

ion-row {
  padding: 0 3vw
}



</style>
