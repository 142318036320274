<template>
  <ion-app>
       
    <ion-router-outlet id="main" />

    <ion-footer v-if="uid && (tags.includes('profile-started') || tags.includes('profile-complete'))">
      <ion-row class="ion-align-items-center ion-justify-content-center">
        <ion-col>
          <router-link :to="'/'">
            <svg width="18.279" height="20.088" viewBox="0 0 18.279 20.088">
              <g id="home" transform="translate(1 1)">
                <path id="Path_5" data-name="Path 5" d="M3,8.331,11.14,2l8.14,6.331v9.948a1.809,1.809,0,0,1-1.809,1.809H4.809A1.809,1.809,0,0,1,3,18.279Z" transform="translate(-3 -2)" fill="none" stroke="#858585" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <path id="Path_6" data-name="Path 6" d="M9,21.044V12h5.426v9.044" transform="translate(-3.574 -2.956)" fill="none" stroke="#858585" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
            </svg>  
          </router-link>  
          </ion-col>
          <ion-col>
            <router-link :to="'/search'">
              <svg width="18.848" height="18.61" viewBox="0 0 18.848 18.61">
                <g id="search" transform="translate(1.198 1.227)">
                  <circle data-name="Ellipse 8" cx="7.5" cy="7.5" r="7.5" transform="translate(-0.198 -0.227)" fill="none" stroke="#858585" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <line data-name="Line 1" x1="3.934" y1="3.934" transform="translate(12.301 12.035)" fill="none" stroke="#858585" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                </g>
              </svg>  
            </router-link>
          </ion-col>
          <ion-col v-if="user.profile.role==='consumer'">
            <svg id="transaction" width="49.362" height="49.362" viewBox="0 0 49.362 49.362">
              <circle class="circle" cx="24.681" cy="24.681" r="24.681" fill="#CCD57E"/>
              <path d="M17.288-12.494H11.156v6.132H8.8v-6.132H2.666v-2.358H8.8v-6.132h2.358v6.132h6.132Z" transform="translate(14.518 38.168)" fill="#fff"/>
            </svg>  
          </ion-col>
          <ion-col v-else-if="user.profile.role==='merchant'">
            <svg id="transaction" width="49.362" height="49.362" viewBox="0 0 49.362 49.362" @click="createNewTransactions">
              <circle class="circle" cx="24.681" cy="24.681" r="24.681" fill="#004F7C"/>
              <path d="M17.288-12.494H11.156v6.132H8.8v-6.132H2.666v-2.358H8.8v-6.132h2.358v6.132h6.132Z" transform="translate(14.518 38.168)" fill="#fff"/>
            </svg>  
          </ion-col>
          <ion-col>
            <router-link :to="'/notifications'">
              <svg id="notifications" width="18.299" height="20.085" viewBox="0 0 18.299 20.085">
                <g  transform="translate(1.019 1)">
                  <path id="Path_7" data-name="Path 7" d="M16.566,7.426a5.426,5.426,0,0,0-10.853,0c0,6.331-2.713,8.14-2.713,8.14H19.279s-2.713-1.809-2.713-8.14" transform="translate(-3 -2)" fill="none" stroke="#858585" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <path id="Path_8" data-name="Path 8" d="M13.4,21a1.809,1.809,0,0,1-3.129,0" transform="translate(-3.695 -3.816)" fill="none" stroke="#858585" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                </g>
              </svg>  
            </router-link>
          </ion-col>
          <ion-col>
          <router-link :to="'/profile'">
            <svg id="profile" width="16.471" height="18.196" viewBox="0 0 16.471 18.196">
              <g transform="translate(1 1.227)">
                  <path id="Path_9" data-name="Path 9" d="M18.471,20.426V18.618A3.618,3.618,0,0,0,14.853,15H7.618A3.618,3.618,0,0,0,4,18.618v1.809" transform="translate(-4 -4.457)" fill="none" stroke="#858585" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  <circle id="Ellipse_9" data-name="Ellipse 9" cx="3.5" cy="3.5" r="3.5" transform="translate(4.354 -0.227)" fill="none" stroke="#858585" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
            </svg>  
          </router-link>
        </ion-col>
      </ion-row>
    </ion-footer>
  </ion-app>
</template>

<script>
/* Vue/Vuex */
import { useStore } from 'vuex';
import { computed, onMounted, watchEffect } from 'vue';
import { useRouter } from 'vue-router'

/* Ionic */
import { IonApp, IonHeader, IonToolbar, IonButton, IonTitle, IonFooter, IonRow, IonCol  } from '@ionic/vue';
import { IonRouterOutlet, popoverController  } from '@ionic/vue';

/* Plugins */
const fb = require('@/firebase/init')

/* Classes */
import { modalService } from "@/classes/modals"
import { popoverService } from "@/classes/popovers"

export default {
  name: 'App',

  components: {
    IonApp, IonHeader, IonToolbar, IonButton, IonTitle, IonFooter, IonRow, IonCol,
    IonRouterOutlet,
  },

  setup() {
    const { state, dispatch } = useStore();
    const router = useRouter()
    const isLoading = computed(() => state.auth.loading);
    const uid = computed(() => state.auth.uid);
    const user = computed(() => state.auth.user);
    const tags = computed(() => state.auth.tags);

    onMounted(() => {
      fb.authenticate.onAuthStateChanged(async user => {
        if (user) {
          await dispatch("initializeUser")
          await dispatch("loadAllCauses")   
        } else {
          console.log("User is not logged in.")
        }
      })
    });

    watchEffect(async () => {
      if( !isLoading.value &&  user.value.profile.role==='consumer') {
        setTimeout(() => {
          if (!tags.value.includes('profile-complete')) { return modalService.triggerProfileBasicInfo() }
          if (tags.value.includes('profile-complete') && !tags.value.includes('welcome-message')) { return popoverService.triggerWelcomeMessage() }
        },800)
      } else if( !isLoading.value &&  user.value.profile.role==='merchant') {
        setTimeout(() => {
          if (!tags.value.includes('profile-complete')) { return modalService.triggerMerchantProfile() }
          if (tags.value.includes('profile-complete') && !tags.value.includes('welcome-message')) { return popoverService.triggerWelcomeMessage() }
        },800)
      }
    });

    const createNewTransactions = async () => {
      if (!tags.value.includes('terms-conditions-accepted')) { return popoverService.triggerPendingAgreement('terms and conditions') }
      else if (!tags.value.includes('service-agreement-accepted')) { return popoverService.triggerPendingAgreement('service agreement') }
      else { return modalService.triggerCreateNewTransaction() }
    }

   
    return {
      uid,
      user,
      tags,

      createNewTransactions
    };
  },

}
</script>

<style scoped>

ion-footer {
  position:fixed;
  bottom:2vh !important;
  max-width: 500px;
  width:94vw;
  margin: 0 3vw;
  padding: 0 8vw !important;
  background-color: #ffffff !important;
  border:1px solid #f3f3f3 !important;
  box-shadow: 0 -2px 8px 0 rgba(0,0,0,0.10) !important;
  border-radius: 30px !important;
}

ion-col { text-align:center; }

#transaction {
  transition-property: all;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1) ease-in-out;
}
#transaction:hover {
  cursor: pointer;
  transform: scale(1.05);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2) ;
}
#transaction .circle { 
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.20) !important;
}

.active #home path,
.active #search circle,
.active #search line,
.active #profile path,
.active #profile circle,
.active #notifications path { stroke:#004F7C; }

</style>
