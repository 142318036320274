<template>
  <ion-content v-if="user.profile.role==='consumer'">
      <ion-row class="ion-text-center">
        <ion-col size="12" class="ixkdhkjfn" style="font-size:18px;">
          Welcome to <span class="red-title" style="padding-left:1vw;font-size:28px;">halona</span>
        </ion-col>
        <ion-col size="12" class="nshroxcdn" style="font-size:28px;padding:10% 0;">
          We'd love to know<br>more about you!
        </ion-col>
        <ion-col size="12" style="padding:0 20vw 5%;">
          <RoundButton :text="'Setup Profile'" :color="'#00777E'" :loading="false" @click="startProfileSetup" />
        </ion-col>
        <ion-col size="12" style="font-size:14px;color:#929292;">
          <span @click="closeModal">Skip For Now</span>
        </ion-col>
      </ion-row>
  </ion-content>
  <ion-content v-else-if="user.profile.role==='merchant'">
      <ion-row class="ion-text-center">
        <ion-col size="12" class="ixkdhkjfn" style="font-size:18px;">
          Welcome to <span class="red-title" style="padding-left:1vw;font-size:28px;">halona</span>
        </ion-col>
        <ion-col size="12" class="nshroxcdn" style="font-size:7vw;padding:10% 0;">
          Complete your account by adding staff members, or fast impact presets
        </ion-col>
        <ion-col size="12" style="padding:0 10% 5%;">
          <RoundButton :text="'Create Staff'" :color="'#00777E'" :loading="false" @click="createStaffModal" />
        </ion-col>
        <ion-col size="12" style="padding:0 10% 5%;">
          <RoundButton :text="'Impact Presets'" :color="'#00777E'" :loading="false" @click="impactPresetModal" />
        </ion-col>
        <ion-col size="12" style="font-size:14px;color:#929292;">
          <span @click="closePopover">Skip For Now</span>
        </ion-col>
      </ion-row>
  </ion-content>
</template>

<script>
/* Vue/Vuex */
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';
import { useRouter } from 'vue-router'

/* Ionic */
import { IonPage, IonContent, IonRow, IonCol } from '@ionic/vue';
import { popoverController  } from '@ionic/vue';

/* Plugins */
const fb = require('@/firebase/init')

/* Classes */
import { modalService } from "@/classes/modals"

/* Components */
import RoundButton from '@/components/buttons/Solid';

export default  {
  name: 'intro',

  components: {
    IonPage, IonContent, IonRow, IonCol,
    RoundButton
  },

  setup() {
    const { state } = useStore();
    const router = useRouter()
    const uid = computed(() => state.auth.uid);
    const user = computed(() => state.auth.user)

    onMounted(async () => {
      await fb.db.collection("Users").doc(uid.value).collection("Tags").doc('welcome-message').set({'created':new Date()})
    })

    const closePopover = async () => {
      return popoverController.dismiss()
    }
      
    const startProfileSetup = async () => {
      closePopover()
      router.push('/profile')
      return modalService.triggerEditProfile()
    }

    const createStaffModal = async () => {
      closePopover()
      return modalService.triggerCreateStaffModal()
    }

    const impactPresetModal = async () => {
      closePopover()
      return modalService.triggerImpactPresetModal()
    }

    return {
      user, 
      closePopover,
      startProfileSetup,
      createStaffModal,
      impactPresetModal
    };
  },

}
</script>
<style scoped>

ion-content {
  --padding-top: 3vh !important;
  --padding-bottom: 3vh !important;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

ion-row {
  padding: 0 3vw
}



</style>
