<template>
  <ion-content>

    <ion-row class="ion-padding">
      <ion-col size="12" style="padding:0!important;text-align:left;">
        <Icon v-if="formState.step==='forgot_password'" :name="'back'" @click="goBack" style="fill:#646464" />
      </ion-col>
      <ion-col size="12" style="text-align:center;padding:5% 0 0;">
        <ion-img :src="require('@/assets/images/color-logo.png')" style="width:180px;margin:0 auto;" />
      </ion-col>
    </ion-row>
    
    <!-- EMAIL STEP (EMAIL) -->
    <ion-row v-if="formState.step==='login'" class="form ion-text-center">
      <ion-col size="12">
        <h4 class="ion-padding-bottom">Log into your existing business account</h4>
        <div style="height:2px;width:30%;margin:0 auto;background:#CCD57E;"></div>
      </ion-col>
      <ion-col size="12">
        <ion-input inputmode="email" type="email" v-model="formState.email" placeholder="Email Address" />
      </ion-col>
      <ion-col size="12">
        <ion-input inputmode="text" type="password" v-model="formState.password" placeholder="******"  />
      </ion-col>
      <ion-col size="12" style="padding:5vh 20vw 0;">
        <RoundButton :text="'Login'" :color="'#00777E'" :loading="loading" @click="submitLogin"/>
        <span style="display:block;margin:3vh 0;font-size:13px;" @click="formState.step='forgot_password'">Forgot Password</span>
      </ion-col>
    </ion-row>

    <!-- FORGOT YOUR PASSWORD? STEP (FORGOT_PASSWORD) -->
    <ion-row v-if="formState.step==='forgot_password'" class="form ion-text-center">
      <ion-col>
        <ion-text color="dark">
          <h4 class="ion-padding-bottom">Reset your password</h4>
          <div style="height:2px;width:30%;margin:0 auto;background:#CCD57E;"></div>
        </ion-text>
        <ion-input placeholder="Email Address" v-model="email_address" inputmode="email" type="email" class="dream-input" enterKeyhint="done"></ion-input>
      </ion-col>
      <ion-col size="12" style="padding:5vh 20vw 0;">
        <RoundButton :text="'Reset'" :color="'#00777E'" :loading="loading" @click="reset_password"/>
      </ion-col>
    </ion-row>

  </ion-content>
</template>

<script>
/* Vue/Vuex */
import { useRouter } from 'vue-router'
import { onMounted, reactive } from 'vue';

/* Firebase */
import firebase from 'firebase/app'
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
const fb = require('@/firebase/init')

/* Ionic */
import { IonContent, IonRow, IonCol, IonInput, IonText } from '@ionic/vue';
import { modalController  } from '@ionic/vue';

/* Mixins */
import {keyboardResize, openToast} from '@/mixins/helpers'

/* Components */
import RoundButton from '@/components/buttons/Solid'
import Icon from '@/components/widgets/Icon.vue'

export default  {
  name: 'Login',

  components: {
    IonContent, IonRow, IonCol, IonInput, IonText,
    RoundButton, Icon
  },

  data: function () {
    return {
      loading:false,
      step: 'email',
      email_address:'',
      formatted_phone_number:'',
      phone_number:'',
      password:'',
      app_verifier: null,
      verification_code:null,
      default_phone_country_code:'+1',
      phone_country_code:'',
    }
  },

  setup() {
    const router = useRouter()

    let formState = reactive({
      step: 'login',
      isLoading: false,
      email:'',
      password:''
    });

    onMounted(() => {
      const user = fb.authenticate.currentUser
      if (user) {
        closeModal()
      } else {
        keyboardResize()
      }
    })

    const goBack = () => {
      if(formState.step==='login') {
        closeModal()
      } else {
        formState.step = 'login'
      }
    };

    const closeModal = () => {
      modalController.dismiss();
    };

    const submitLogin = async () => {
      formState.isloading = true

      const auth = getAuth();
      await signInWithEmailAndPassword(auth, formState.email, formState.password)
        .then((userCredential) => {
          // Signed in 
          const user = userCredential.user;
          formState.isloading = false
          closeModal()
          router.push('/')
        })
        .catch(async (error) => {
          formState.isLoading = false

          if('auth/user-not-found' == error.code) {
            await openToast('User not found')
          }
          else if('auth/invalid-email' == error.code) {
            await openToast('Invalid email supplied')
          }
          else if('auth/invalid-email-verified' == error.code) {
            await openToast('Email address is not verified')
          }
          else if('auth/invalid-password' == error.code) {
            await openToast('Invalid password')
          }
          else if('auth/wrong-password' == error.code) {
            await openToast('Invalid password')
          }
          else {
            await openToast('ERROR LOGIN: '+ error.message)
          }
          console.log('error:', error.code, error.message)
        })
    };    

    return { 
      formState,

      goBack,
      closeModal,
      submitLogin
    };
  },

}

</script>

<style scoped>
ion-content {
  background:#ffffff;
}

.form {
  padding:1.5vh 10vw
}
</style>