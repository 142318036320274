import mutations from './mutations';

const fb = require('@/firebase/init')

const state = () => ({
  loading: true,
  user: null,
  referral_code: null,
  phone_number: null,
  email: null,
  uid: null,
  notifications: null,
  notificationsCount: 0,
  friends: [],
  tags:[],
  error: null,
});

const actions = {
  async initializeUser({ commit, dispatch }) {
    try {
      await commit('loadUserBegin');

      const user = fb.authenticate.currentUser

      const dbUser = fb.db.collection('Users').doc(user.uid)
      const profile = await dbUser.get()

      if (profile.exists) {
        await commit('loadUser', profile);

        dbUser.update({'dates.last_login':new Date()})

        await dispatch('watchUserTags', user.uid);

        //await dispatch('getNotificationsCount', user.uid)
        //await dispatch('getUserImpact', user.uid)

        //Watch If New Helcim Order Is Processed
        //await dispatch('getHelcimTransactions', user.uid)
      }

      await commit('loadUserSuccess');
    } catch (err) {
      console.log(err);
      await commit('loadUserErr', err);
    }
  },

  async watchUserTags({ commit, state }, uid) {
    try {
      console.log("Loading Tags...")

      const query = await fb.db.collection('Users').doc(uid).collection('Tags')
      await query.onSnapshot(async (snapshot) => {
        snapshot.forEach((doc) => {
          state.tags.push(doc.id)
        })
      }, err => {
        console.log(`Encountered error: ${err}`);
      });

    } catch (err) {
      console.log(err);
      await commit('loadUserErr', err);
    }
  },

  async getNotificationsCount({ commit }, uid) {
    try {
      const notifications = await fb.db.collection('Users').doc(uid).collection('Notifications').where('viewed','==', false).get()
      await commit('loadUserNotificationsCountsSuccess', notifications.size);
    } catch (err) {
      console.log(err);
      await commit('loadUserErr', err);
    }
  },

  async getUserNotifications({ commit }, uid) {
    const queryData = {};
    try {
      await commit('loadUserBegin');
      console.log("Loading Notifications")
      const notifications = await fb.db.collection('Users').doc(uid).collection('Notifications').limit(100).get()
      await notifications.forEach(doc => {
        queryData[doc.id] = {
          id: doc.id,
          viewed: doc.data().viewed,
          uid: doc.data().uid,
          ...doc.data().notification
        }
      });
      await commit('loadUserNotificationsSuccess', queryData);
    } catch (err) {
      console.log(err);
      await commit('loadUserNotificationsErr', err);
    }
  },

  async getUserImpact({ rootState, commit }, uid) {
    try {
      const notifications = await fb.db.collection('Users').doc(uid).collection('Impact').get()
      await notifications.forEach(doc => {
        rootState.causes.causes[doc.id].impact = doc.data().value
      });
    } catch (err) {
      console.log(err);
      await commit('loadUserNotificationsErr', err);
    }
  },

  async getUserFriends({ commit }, uid) {
    const queryData = [];
    try {
      await commit('loadUserBegin');
      const friends = await fb.db.collection('Users').doc(uid).collection('Following').get()
      console.log('GET FRIENDS')
      await friends.forEach(doc => {
        console.log(doc)
      });
      await commit('loadUserFriendsSuccess', queryData);
    } catch (err) {
      console.log(err);
      await commit('loadUserFriendsErr', err);
    }
  },

  async validateEmailAddress({}, email) {
    let e = email.toLowerCase()

    // Verify email is available
    return await fb.db.collection("Users")
      .where("profile.email", "==", e)
      .get()
      .then(results => {
        if (results.empty) {
          // email was not found, user can have it
          return true
        } else {
          console.log('email already used')
          let res = null
          results.forEach(u => {
            res = u.data()
          })
          return res
        }
      })
      .catch(error => {
        console.log('ERROR GETTING EMAIL', error)
        return false
      })
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
