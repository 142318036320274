// Only import what you need from firebase
import firebase, { initializeApp } from 'firebase/compat/app'
import 'firebase/compat/functions'
import 'firebase/compat/auth'
import 'firebase/compat/storage'
import 'firebase/compat/firestore'

// Connect to Firebase
const firebaseConfig = require('./fbConfigKey.json')
firebase.initializeApp(firebaseConfig)

//Globals
const db = firebase.firestore()
const firestore = firebase.firestore
const authenticate = firebase.auth()
const storage = firebase.storage()
const functions = firebase.functions()

db.settings ({
  cacheSizeBytes:firebase.firestore.CACHE_SIZE_UNLIMITED
})

/* Global Firestore Functions */
const tagUser = firebase.functions().httpsCallable('callable-tagUser')
const inviteExternalContact = firebase.functions().httpsCallable('callable-inviteExternalContact')
const notifyUser = firebase.functions().httpsCallable('notifications-notifyUser')


export {
  db,
  authenticate,
  storage,
  firestore,
  functions,
  tagUser,
  notifyUser,
  inviteExternalContact,
}

