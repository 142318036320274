<template>
  <ion-content>

    <ion-row class="ion-padding">
      <ion-col size="12" style="text-align:center;padding:5% 0 0;">
        <ion-img :src="require('@/assets/images/color-logo.png')" style="width:180px;margin:0 auto;" />
      </ion-col>
    </ion-row>
      
    <ion-row v-if="formState.step===1" class="form ion-text-center">
        <ion-col size="12" class="ion-padding-bottom">
          <h4>Company Info</h4>
          <div style="height:2px;width:30%;margin:0 auto;background:#CCD57E;"></div>
        </ion-col>
        <ion-col size="12">
          <ion-input inputmode="text" type="text" v-model="formState.name" placeholder="Company Name" />
        </ion-col>
        <ion-col size="12">
          <ion-input inputmode="tel" type="text" v-model="formState.phone" placeholder="(000) 000-0000" @input="formatPhoneNumber($event)" />
        </ion-col>
        <ion-col size="12">
          <ion-select interface="popover" placeholder="Category" @ionChange="formState.category=$event.target.value">
            <ion-select-option v-for="item in formState.categories" :key="item.id" :value="item.id">{{item.title}}</ion-select-option>
          </ion-select>
        </ion-col>

        <ion-col size="12" style="padding:5vh 20vw 0;">
          <RoundButton :text="'Next'" :color="'#00777E'" :loading="formState.isLoading" @click="validateStepOne" />
        </ion-col>
    </ion-row>

    <ion-row v-else-if="formState.step===2" class="form ion-text-center">
        <ion-col size="12" class="ion-padding-bottom">
          <h4>Set Location</h4>
          <div style="height:2px;width:30%;margin:0 auto;background:#CCD57E;"></div>
        </ion-col>
        <ion-col size="12">
          <ion-input inputmode="text" type="text" v-model="formState.address" placeholder="Address" />
        </ion-col>
        <ion-col size="12">
          <ion-input inputmode="text" type="text" v-model="formState.city" placeholder="City"  />
        </ion-col>
        <ion-col size="12">
          <ion-select interface="popover" placeholder="Country" @ionChange="formState.country=$event.target.value">
            <ion-select-option value="cad">Canada</ion-select-option>
            <ion-select-option value="usa">United States</ion-select-option>
          </ion-select>
        </ion-col>
        <ion-col size="12">
          <ion-input inputmode="text" type="text" v-model="formState.postalzip" placeholder="Postal/Zip Code"  />
        </ion-col>

        <ion-col size="12" style="padding:5vh 20vw 0;">
          <RoundButton :text="'Next'" :color="'#00777E'" :loading="formState.isLoading" @click="validateStepTwo" />
        </ion-col>
    </ion-row>

    <ion-row v-else-if="formState.step===3" class="form ion-text-center">
      <ion-col size="12" class="ion-padding-bottom">
        <h4>More Details</h4>
        <div style="height:2px;width:30%;margin:0 auto;background:#CCD57E;"></div>
      </ion-col>
      <ion-col size="12">
        <ion-textarea :autoGrow="true" inputmode="text" v-model="formState.description" placeholder="A description on your company... " />
      </ion-col>
      <ion-col size="12">
        <ion-input inputmode="text" type="text" v-model="formState.tags.search" placeholder="Add searchable tags" />
        <div v-if="formState.tags.display" class="search-results">
          <ul>
            <li v-for="item in formState.tags.results" :key="item.id" @click="selectTag(item.id)">{{item.title}}</li>  
            <li v-if="!formState.tags.results.length" @click="createNewTag">No results found. Click to create.</li>  
          </ul>
        </div>
      </ion-col>
      <ion-col size="12" class="ion-text-left">
        <span class="tag" v-for="item in formState.tags.tags" :key="item.id" @click="removeTag(item.id)">{{item.title}}</span>
      </ion-col>
      <ion-col size="12" style="padding:5vh 20vw 0;">
        <RoundButton :text="'Next'" :color="'#00777E'" :loading="formState.isLoading" @click="validateStepThree" />
      </ion-col>
    </ion-row>

    <ion-row v-else-if="formState.step===4" class="form ion-text-center">
      <ion-col size="12" class="ion-padding-bottom">
        <h4>Terms & Conditions</h4>
        <div style="height:2px;width:30%;margin:0 auto;background:#CCD57E;"></div>
      </ion-col>
      <ion-col size="12">
      </ion-col>
      <ion-col size="12" style="padding:5vh 20vw 0;">
        <RoundButton :text="'Agree'" :color="'#00777E'" :loading="formState.isLoading" @click="validateStepFour" />
        <span style="display:block;color:#ACACAC;font-size:16px;padding:10% 0 0;" @click="skipStepFour">Skip for now</span>
      </ion-col>
    </ion-row>

    <ion-row v-else-if="formState.step===5" class="form ion-text-center">
      <ion-col size="12" class="ion-padding-bottom">
        <h4>Service Agreement</h4>
        <div style="height:2px;width:30%;margin:0 auto;background:#CCD57E;"></div>
      </ion-col>
      <ion-col size="12">
      </ion-col>
      <ion-col size="12" style="padding:5vh 20vw 0;">
        <RoundButton :text="'Agree'" :color="'#00777E'" :loading="formState.isLoading" @click="validateStepFive" />
        <span style="display:block;color:#ACACAC;font-size:16px;padding:10% 0 0;" @click="skipStepFive">Skip for now</span>
      </ion-col>
    </ion-row>

  </ion-content>
</template>

<script>
/* Vue/Vuex */
import { useStore } from 'vuex';
import { computed, onMounted, watchEffect, reactive } from 'vue';
import { useRouter } from 'vue-router'

/* Firebase */
import 'firebase/auth'
const fb = require('@/firebase/init')

/* Mixins */
import {openToast} from '@/mixins/helpers'

/* Ionic */
import { IonPage, IonContent, IonRow, IonCol, IonImg, IonInput, IonTextarea, IonSelect, IonSelectOption } from '@ionic/vue';
import { modalController, alertController  } from '@ionic/vue';

/* Components */
import RoundButton from '@/components/buttons/Solid';

/* Classes */
import { popoverService } from "@/classes/popovers"

export default  {
  name: 'intro',

  components: {
    IonPage, IonContent, IonRow, IonCol, IonImg, IonInput, IonTextarea, IonSelect, IonSelectOption,
    RoundButton
  },

  setup() {
    const { state } = useStore();
    const uid = computed(() => state.auth.uid);
    const user = computed(() => state.auth.user);
    const tags = computed(() => state.auth.tags);

    let formState = reactive({
      isLoading: false,
      step: 1,
      name:'',
      phone:'',
      category:null,
      categories:[],
      address:'',
      city:'',
      country:'',
      postalzip: '',
      description:'',
      tags: {
        search: '',
        results: {},
        display:false,
        tags: {}
      }
    });

    onMounted(async () => {
      const categories = await fb.db.collection("Data").doc("Merchants").collection("Categories").get()

      for (const doc of categories.docs) {
        formState.categories.push({id: doc.id, ...doc.data()})
      }

    })

    watchEffect(async () => {
      if (!tags.value.includes('profile-basic-info')) { formState.step=1 }
      else if (!tags.value.includes('profile-set-location')) { formState.step=2 }
      else if (!tags.value.includes('profile-more-details')) { formState.step=3 }
      else if (tags.value.includes('terms-conditions-accepted') || tags.value.includes('terms-conditions-skipped')) { formState.step=4 }
      else if (!tags.value.includes('service-agreement-accepted') || !tags.value.includes('service-agreement-skipped')) { formState.step=5 }

      if (formState.tags.search) {
        formState.tags.display = true 

        const results = await fb.db.collection('Tags').where('title','>', formState.tags.search.toLowerCase()).get()
        if (results.size>0) {
          for (const doc of results.docs) {
            formState.tags.results[doc.id] = {id:doc.id,title:doc.data().title}
          }
        }
      } else {
        formState.tags.display = false
        formState.tags.results = {}
      }
    })

    const closeModal = () => {
      modalController.dismiss();
    };

    const formatPhoneNumber = async (e) => {
      const x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      formState.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    }

    const validateStepOne = async () => {
      formState.isLoading = true
      
      if (!formState.name) {
        await openToast('You must set a company name.')
      } else if (!formState.phone) {
        await openToast('You must set a phone number.')
      } else if (!formState.category) {
        await openToast('Please select a category for your company.')
      } else {
        const number = ('+1'+formState.phone).replace(/[^\d\+]/g,"")
        fb.db.collection("Users").doc(uid.value).update({'profile.company_name':formState.name,'profile.phone':number,'profile.category':formState.category,}).then(() => {
          fb.db.collection("Users").doc(uid.value).collection('Tags').doc('profile-basic-info').set({'created':new Date()})
          formState.step=2
        })
      }
      
      formState.isLoading = false
    }

    const validateStepTwo = async () => {
      formState.isLoading = true
      
      if (!formState.address) {
        await openToast('You must set an address.')
      } else if (!formState.city) {
        await openToast('You must set a city.')
      } else if (!formState.country) {
        await openToast('You must set a country.')
      } else if (!formState.postalzip) {
        await openToast('You must set a posta/zip code.')
      } else {
        fb.db.collection("Users").doc(uid.value).update({'profile.address':formState.address,'profile.city':formState.city,'profile.country':formState.country,'profile.postalzip':formState.postalzip}).then(() => {
          fb.db.collection("Users").doc(uid.value).collection('Tags').doc('profile-set-location').set({'created':new Date()})
          formState.step=3
        })
      }
      
      formState.isLoading = false
    }

    const validateStepThree = async () => {
      formState.isLoading = true
      
      fb.db.collection("Users").doc(uid.value).update({'profile.description':formState.description,'profile.tags':formState.tags}).then(() => {
        fb.db.collection("Users").doc(uid.value).collection('Tags').doc('profile-more-details').set({'created':new Date()})
        formState.step=4
      })
      
      formState.isLoading = false
    }

    const validateStepFour = async () => {
      formState.isLoading = true
      
      fb.db.collection("Users").doc(uid.value).collection('Tags').doc('terms-conditions-accepted').set({'created':new Date()}).then(() => {
        formState.step=5
      })
      
      formState.isLoading = false
    }

    const skipStepFour = async () => {
      formState.isLoading = true
      
      fb.db.collection("Users").doc(uid.value).collection('Tags').doc('terms-conditions-skipped').set({'created':new Date()}).then(() => {
        formState.step=5
      })
      
      formState.isLoading = false
    }

    const validateStepFive = async () => {
      formState.isLoading = true
      
      await fb.db.collection("Users").doc(uid.value).collection('Tags').doc('service-agreement-accepted').set({'created':new Date()}).then(() => {
        fb.db.collection("Users").doc(uid.value).collection('Tags').doc('profile-complete').set({'created':new Date()})
        return closeModal()
      })

      return popoverService.triggerWelcomeMessage()
    }

    const skipStepFive = async () => {
      formState.isLoading = true
      
      await fb.db.collection("Users").doc(uid.value).collection('Tags').doc('service-agreement-skipped').set({'created':new Date()}).then(() => {
        fb.db.collection("Users").doc(uid.value).collection('Tags').doc('profile-complete').set({'created':new Date()})
        return closeModal()
      })
      
      return popoverService.triggerWelcomeMessage()
    }

    const createNewTag = async () => {
      const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: 'Create New Tag',
          message: 'Please input a single word tag to help your business be more visible in the explore map.',
          inputs: [
            {
              name: 'title',
              id: 'title-id',
              value: 'Tag Name',
            },
          ],
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                console.log('Confirm Cancel')
              },
            },
            {
              text: 'Save',
              handler: (alertData) => {
                fb.db.collection('Tags').add({'title':alertData.title.toLowerCase(),'created':new Date()}).then(doc => {
                  formState.tags.results[doc.id] = {id: doc.id, title: alertData.title}
                })
              },
            },
          ],
        });
      return alert.present();
    }

    const selectTag = async (id) => {
      formState.tags.tags[id] = {id: id, title: formState.tags.results[id].title}
      formState.tags.search = ''
      formState.tags.display= false
    }

    const removeTag = async (id) => {
      delete formState.tags.tags[id]
    }

    return {
      formState,

      formatPhoneNumber,
      validateStepOne,
      validateStepTwo,
      validateStepThree,
      validateStepFour, skipStepFour,
      validateStepFive, skipStepFive,
      createNewTag, selectTag, removeTag
    };
  },

}
</script>
<style scoped>

.form ion-col {
  padding:1.5vh 10vw
}


ion-select {
  --placeholder-color:#acacac;
  --placeholder-opacity:1
}

ion-textarea {
  min-height:25vh
}

.search-results {
  background:#ffffff;
  border-radius:8px;
  border: 1px solid #c4c4c4;
  box-shadow: 2px 3px 8px rgba(0,0,0,0.3);
  max-height:350px;
  overflow-y:scroll
}
.search-results ul {
  padding: 0;
  text-align:left
}
.search-results li {
  padding:10px 20px;
  list-style-type: none;
  font-size:14px;
}
.search-results li:hover {
  background:#f5f5f5;
  cursor: pointer;
}

.tag {
  background:#CED389;
  color: #ffffff;
  font-size:12px;
  border-radius: 25px;
  padding: 10px 20px
}
</style>
