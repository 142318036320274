import mutations from './mutations';

const fb = require('@/firebase/init')

const state = () => ({
  loading: true,
  allDiscussions:null,
  usersDiscussions:null,
  followingDiscussions:null,
  
  hashtagFeed:null,
  savedFeed: null,
  comments:null,
  replies:null,
  error:null,
});

const actions = {
  async initDiscussions({ dispatch }, uid) {
    try {
      dispatch('loadAllDiscussions')
      dispatch('loadFeedDiscussions', uid)
      dispatch('loadUserDiscussions', uid)
    } catch (err) {
      console.log(err);
    }
  },

  async loadAllDiscussions({ commit }) {
    let queryData = [];
    try {
      await commit('loadDiscussionsBegin');
      const query = await fb.db.collection("Discussions").orderBy('created', 'desc').get()
      await query.forEach(async (doc) => {
        queryData = {
          ...queryData,
          [doc.id]: {
            id: doc.id,
            ...doc.data()
          }
        }
      })
      await commit('loadAllDiscussionsSuccess', queryData);
    } catch (err) {
      console.log(err);
      await commit('loadDiscussionsErr', err);
    }
  },

  async loadFeedDiscussions({ commit }, uid) {
    // Dashboard was getting errors trying to load discussions
    if(!uid) {
      return false
    }
    try {
      await commit('loadDiscussionsBegin');
      const query = await fb.db.collection("Users").doc(uid).collection('Discussions').orderBy('created', 'desc').get()
      await commit('loadFeedDiscussionsSuccess', query);
    } catch (err) {
      console.log(err);
      await commit('loadDiscussionsErr', err);
    }
  },

  async loadHashtagDiscussions({ commit }, hashtag) {
    const hash = hashtag.trim().replace(/, +/g, ',')
    try {
      await commit('loadDiscussionsBegin');
      const query = await fb.db.collection("Hashtags").doc(hash).collection('Discussions').orderBy('created', 'desc').get()
      await commit('loadHashtagDiscussionsSuccess', query.docs);
    } catch (err) {
      console.log(err);
      await commit('loadDiscussionsErr', err);
    }
  },

  async loadSavedDiscussions({ commit }, uid) {
    try {
      await commit('loadDiscussionsBegin');
      const query = await fb.db.collection("Users").doc(uid).collection('Saved').orderBy('created', 'desc').get()
      await commit('loadSavedDiscussionsSuccess', query.docs);
    } catch (err) {
      console.log(err);
      await commit('loadDiscussionsErr', err);
    }
  },

  async loadUserDiscussions({ commit }, uid) {
    let queryData = [];
    try {
      await commit('loadDiscussionsBegin');
      const query = await fb.db.collection("Discussions").where('uid','==',uid).orderBy('created', 'desc').get()
      await query.forEach(async (doc) => {
        queryData = {
          ...queryData,
          [doc.id]: {
            id: doc.id,
            ...doc.data()
          }
        }
      })
      await commit('loadUserDiscussionsSuccess', queryData);
    } catch (err) {
      console.log(err);
      await commit('loadDiscussionsErr', err);
    }
  },

  async loadDiscussionComments({ commit }, id) {
    let queryData = [];
    try {
      await commit('loadDiscussionsBegin');
      const query = await fb.db.collection("Discussions").doc(id).collection("Comments").orderBy('created', 'desc').get()
      await query.forEach(async (doc) => {
        queryData = {
          ...queryData,
          [doc.id]: {
            id: doc.id,
            replies:null,
            ...doc.data()
          }
        }
      })
      await commit('loadDiscussionCommentsSuccess', queryData);
    } catch (err) {
      console.log(err);
      await commit('loadDiscussionsErr', err);
    }
  },

  async loadCommentReplies({ commit }, data) {
    let queryData = [];
    try {
      await commit('loadDiscussionsBegin');
      const query = await fb.db.collection("Discussions").doc(data.discussion).collection("Comments").doc(data.comment).collection("Replies").orderBy('created', 'desc').get()
      await query.forEach(async (doc) => {
        queryData = {
          ...queryData,
          [doc.id]: {
            id: doc.id,
            ...doc.data()
          }
        }
      })
      await commit('loadCommentRepliesSuccess', {replies:queryData, comment:data.comment});
    } catch (err) {
      console.log(err);
      await commit('loadDiscussionsErr', err);
    }
  },

  async loadRepostDiscussion({ commit }, id) {
    try {
      const query = await fb.db.collection("Discussions").doc(id).get()
      return query
    } catch (err) {
      console.log(err);
      await commit('loadDiscussionsErr', err);
    }
  },

  async getUserDetails({ }, uid) {
    try {
      return await fb.db.collection('Users').doc(uid).get()
    } catch (err) {
      console.log(err);
    }
  },

  async checkUserLiked({ rootState }, id) {
    try {
      const likes = await fb.db.collection("Discussions").doc(id).collection("Likes").doc(rootState.auth.uid).get()
      if(likes.exists) { return true } else { return false }
    } catch (err) {
      console.log(err);
    }
  },

  async saveDiscussion({ commit }, data) {
    try {
      await fb.db.collection("Users").doc(data.uid).collection("Saved").doc(data.discussion).set({'created':new Date})
    } catch (err) {
      console.log(err);
      await commit('loadDiscussionsErr', err);
    }
  },

  async unsaveDiscussion({ commit }, data) {
    try {
      await fb.db.collection("Users").doc(data.uid).collection("Saved").doc(data.discussion).delete()
    } catch (err) {
      console.log(err);
      await commit('loadDiscussionsErr', err);
    }
  },

};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
