<template>
  <ion-content class="ion-padding">
    <ion-row style="padding:0 0 5%">
      <ion-col size="6">
        <span>Save</span>
      </ion-col>
      <ion-col size="6" class="ion-text-right">
        <span @click="closeModal()">Cancel</span>
      </ion-col>
      <ion-col size="12" class="ion-text-center">
        <span class="profile-photo">
          <img v-if="user.profile.profile_photo" :src="user.profile.profile_photo">
        </span>
      </ion-col>
      <ion-col size="12" class="ion-text-center">
        <span style="font-weight:600;color:#004F7C;font-size:13px;text-transform:uppercase;">{{user.profile.company_name}}</span>
        <span style="display:block;color:#9F9F9F;font-size:12px;">Joined {{moment(user.dates.created.toDate()).format('MMMM d, YYYY')}}</span>
      </ion-col>
      <ion-col size="12" style="padding:2vh 0;" class="ion-text-center">
        <h4 style="font-size:16px;text-transform:uppercase;font-weight:600;color:#004F7C;padding:2vh 0">Update Password</h4>
        <div style="height:2px;width:30%;margin:0 auto;background:#CCD57E;"></div>
      </ion-col>
      <ion-col>
        <ion-item>
          <ion-label>Current Password</ion-label>
          <ion-input inputmode="text" type="text" v-model="formState.password" />
        </ion-item>
        <ion-item class="border">
          <ion-label>New Password</ion-label>
          <ion-input inputmode="text" type="text" v-model="formState.newPassword" />
        </ion-item>        
        <ion-item class="border">
          <ion-label>Confirm New Password</ion-label>
          <ion-input inputmode="text" type="text" v-model="formState.confirmNewPassword" />
        </ion-item> 
      </ion-col>
    </ion-row>


  </ion-content>
</template>

<script>
/* Vue/Vuex */
import { useStore } from 'vuex';
import { computed, watchEffect, onUnmounted, reactive } from 'vue';

/* Ionic */
import { IonContent, IonRow, IonCol, IonItem, IonLabel, IonInput } from '@ionic/vue';

/* Components */
import Icon from '@/components/widgets/Icon.vue'
import OutlinedButton from '@/components/buttons/Outlined'

/* Plugins */
const fb = require('@/firebase/init')
import moment from 'moment'

import { isValidPassword } from '@/mixins/validation'

/* Classes */
import { modalService } from "@/classes/modals"

export default  {
  name: 'Friends',

  components: {
    IonContent, IonRow, IonCol, IonItem, IonLabel, IonInput,
    Icon, OutlinedButton
  },

  setup() {
    const { state } = useStore();
    const user = computed(() => state.auth.user);
    const uid = computed(() => state.auth.uid);
    const modals = computed(() => state.modal);

    let formState = reactive({
      isLoading: true,
      password: '',
      newPassword: '',
      confirmNewPassword: ''
    });

    onUnmounted(() => {
      delete modals.value['merchant-edit-password']
    })

    const closeModal = async () => {
      modalService.triggerEditMerchantPasswordModal();
    };

    const updateCompanyName =  () => {
      if (user.value.profile.companyName!==formState.company_name) {
        fb.db.collection("Users").doc(uid.value).update({
          'profile.company_name': formState.company_name.toLowerCase(),
        }).then(async () => {
          user.value.profile.company_name=formState.companyName.toLowerCase()
          await openToast('Your company name has been successfully updated.')
        }).catch(async (error) => {
          await openToast('There was an issue saving your company name.')
        })
      }
    }

    const updatePhoneNumber = async () => {
      if (formState.username.length<10) { return await openToast('Your phone number must be 10 digits.') }

      if (formState.phone && user.value.profile.phone!==formState.phone) {
        fb.db.collection("Users").doc(uid.value).update({
          'profile.phone': Number(formState.phone)
        }).then(async () => {
          user.value.profile.phone=formState.phone
          await openToast('Your phone number has been successfully updated.')
        }).catch(async (error) => {
          await openToast('There was an issue saving your phone number.')
        })
      }
    }

    watchEffect(async () => {
       if (user && user.value) {
        formState.companyName = user.value.profile.company_name ? user.value.profile.company_name : ''
        formState.email = user.value.profile.email
        formState.phone = user.value.profile.phone ? user.value.profile.phone : null

        formState.isLoading = false
      }
    })
   
    return { 
      user,
      formState,
      moment,

      closeModal,
      updateCompanyName,
      updatePhoneNumber
    };
  },

}

</script>

<style scoped>

.profile-photo {
  display:block;
  width:99px;
  height:99px;
  margin:0 auto;
  border:3px solid #004F7C;
  background:#9F9F9F;
  border-radius:100%
}

.profile-photo img { border-radius:100%;}

ion-item {
  border:1px solid #ACACAC;
  border-radius: 25px;
  margin: 0 0 3vh;
}

ion-input, ion-select {
  border: none;
}

ion-label {
  font-size:13px !important;
  color:#ACACAC !important;
}

ion-input {
  font-size:14px !important;
  color:#0f0f0f !important;
}

</style>