<template>
  <ion-row class="ion-text-center">
    <ion-col size="4" v-bind:class="{selected: transaction.format == 'hourly'}" @click="selectFormat('hourly')">
      <p style="font-size:40px;font-weight:600">$</p>
      <p>hourly</p>
    </ion-col>
    <ion-col size="4" v-bind:class="{selected: transaction.format == 'percentage'}" @click="selectFormat('percentage')">
      <p style="font-size:40px;font-weight:600">%</p>
      <p>percentage</p>
    </ion-col>
    <ion-col size="4" v-bind:class="{selected: transaction.format == 'flat rate'}" @click="selectFormat('flat rate')">
      <p style="font-size:40px;font-weight:600">-</p>
      <p>flat rate</p>
    </ion-col>
  </ion-row>
</template>

<script>
/* Vue/Vuex */
import { useStore } from 'vuex';
import { computed } from 'vue';

/* Ionic */
import { IonRow, IonCol } from '@ionic/vue'

export default {
  components: {
    IonRow, IonCol,
  },

  setup() {
    const { state } = useStore();
    const transaction = computed(() => state.transactions.transaction);

    const selectFormat = (id) => {
      if (state.transactions.transaction.format===id) {
        state.transactions.transaction.format = null
      } else {
        state.transactions.transaction.format = id
      }      
    }

    return {
      transaction,

      selectFormat
    }
  }

}
</script>

<style>
ion-col.selected p {
  color: #004f7c !important
}

ion-col p {
  padding: 0;
  margin:0;
  font-size:13px;
  font-weight:600;
  color: #8E8E8E
}
</style>