<template>
  <ion-button v-if="!loading" :id="id" expand="block" shape="round" :size="size" :style="'--color: '+textcolor+';--background:'+color+';--background-activated:'+activated+';'">{{text}}</ion-button>
  <ion-button v-else disabled="true" :id="id" expand="block" shape="round" :size="size" :style="'--color: '+textcolor+';--background:'+color+';--background-activated:'+activated+';'"><ion-spinner style="display:block;height:50%;margin:0 auto;"></ion-spinner></ion-button>
</template>

<script>
import { IonButton, IonSpinner } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { 
    IonButton, IonSpinner
  },

  props: {
    loading: { default: false, type:Boolean },
    text: { default: null, type:String },
    textcolor: { default:'#ffffff', type:String },
    color: { default: '#CCD57E', type: String },
    activated: { default: '#CBCFA4', type: String },
    id: { default: '', type: String },
    size: {default: '4vw', type: String }
  }

});
</script>

<style>

ion-button  {
  font-weight:700;
  font-size:14px;
  text-transform: uppercase;
}

</style>
