<template>
  <svg
    class="icon"
    :style="'width:24px;height:24px;fill:'+color+';'"
    viewBox="0 0 24 24"
  >
    <path :d="path" />
  </svg>
</template>

<script>
import icons from '@/assets/icons.json';

export default {
  name: 'Icon',
  props: {
    name: String,
    color: String,
  },
  computed: {
    path() {
      return icons[this.name];
    },
  },
};
</script>