<template>
  <ion-content>

    <ion-row class="ion-padding">
        <ion-col size="12" style="text-align:center;padding:5% 0 0;">
          <ion-img :src="require('@/assets/images/color-logo.png')" style="width:180px;margin:0 auto;" />
        </ion-col>
      </ion-row>
      
      <ion-row class="form ion-text-center">
        <ion-col size="12" class="ion-padding-bottom">
          <h4>Business Sign Up</h4>
          <div style="height:2px;width:30%;margin:0 auto;background:#CCD57E;"></div>
        </ion-col>
        <ion-col size="12">
          <ion-input inputmode="email" type="email" v-model="formState.email" placeholder="Email Address" />
        </ion-col>
        <ion-col size="12">
          <ion-input inputmode="text" type="password" v-model="formState.password" placeholder="******"  />
        </ion-col>
        <ion-col size="12" style="padding:0 10vw 5vh;">
          <ion-input inputmode="text" type="password" v-model="formState.confirmPassword" placeholder="******"  />
        </ion-col>
        <ion-col size="12" style="padding:0 20vw;">
          <RoundButton :text="'Sign Up'" :color="'#00777E'" :loading="formState.isLoading" @click="validateSubmission" />
        </ion-col>
        <ion-col size="12" style="padding:1vh 20vw 0;">
          <span @click="closeModal" style="display:block;color:#969696;font-size:13px;margin:10% 0 0;">Cancel</span>
        </ion-col>
      </ion-row>

  </ion-content>
</template>

<script>
/* Vue/Vuex */
import { useStore } from 'vuex';
import { reactive } from 'vue';
import { useRouter } from 'vue-router'

/* Firebase */
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
const fb = require('@/firebase/init')

/* Mixins */
import {openToast} from '@/mixins/helpers'

/* Ionic */
import { IonPage, IonContent, IonRow, IonCol, IonImg, IonInput, IonSelect, IonSelectOption } from '@ionic/vue';
import { modalController  } from '@ionic/vue';

/* Components */
import RoundButton from '@/components/buttons/Solid';

export default  {
  name: 'intro',

  components: {
    IonPage, IonContent, IonRow, IonCol, IonImg, IonInput, IonSelect, IonSelectOption,
    RoundButton
  },

  setup() {
    const { dispatch } = useStore();
    const router = useRouter()

    let formState = reactive({
      isLoading: false,
      email:'',
      password:'',
      confirmPassword:''
    });

    const closeModal = () => {
      modalController.dismiss();
    };

    const validateSubmission = async () => {
      formState.isLoading = true
      
      if (!formState.email) {
        await openToast('You must input an email address.')
      } else if (!formState.password) {
        await openToast('You must set a password.')
      } else if (!formState.confirmPassword) {
        await openToast('Please confirm your password.')
      } else if (formState.password !== formState.confirmPassword) {
        await openToast('Your passwords do not match..')
      } else {
        createNewAccount()
      }
      
      formState.isLoading = false
    }

    const createNewAccount = async () => {
      formState.isLoading = true

      const auth = getAuth();
      createUserWithEmailAndPassword(auth, formState.email, formState.password)
        .then((userCredential) => {
          // Signed in 
          const user = userCredential.user;
          fb.db.collection('Users').doc(user.uid).set({
          'dates': {'created':new Date(),'last_login':new Date()},
          'profile': {
            'uid':user.uid,
            'email':formState.email.toLowerCase(),
            'role': 'merchant',
            'active': true,
            'profile_photo': null,
          }
        }, {merge:true})
          .then(async () => {
            formState.isLoading = false
            router.push('/')
            closeModal()
          }).catch( () => {
            formState.isLoading = false
            console.log('ERROR adding to global')
          })
        })
        .catch((error) => {
          formState.isLoading = false

          if('auth/user-not-found' === error.code) {
            openToast('User not found', 'warning')
          }
          else if('auth/invalid-email' === error.code) {
            openToast('Invalid email supplied', 'warning')
          }
          else if('auth/invalid-email-verified' === error.code) {
            openToast('Email address is not verified', 'warning')
          }
          else if('auth/invalid-password' === error.code) {
            openToast('Invalid password', 'warning')
          }
          else if('auth/email-already-in-use' === error.code) {
            openToast('Account already exists, try logging in', 'warning')
          } else {
            openToast('ERROR: '+ error.message)
            console.log('error:', error.code, error.message)
          }
        })
    }

    return {
      formState,
      closeModal,
      validateSubmission
    };
  },

}
</script>
<style scoped>
.form ion-col {
  padding:1.5vh 10vw
}
</style>
