<template>
  <ion-content>

    <ion-row class="ion-padding">
      <ion-col size="12" style="text-align:center;padding:5% 0 0;">
        <ion-img :src="require('@/assets/images/halona-icon.png')" style="width:120px;margin:0 auto;" />
      </ion-col>
    </ion-row>
    
    <ion-row class="form ion-text-center">
      <ion-col size="12">
        <span class="ixkdhkjfn">Welcome to</span>
        <span class="red-title" style="padding-left:2%">Halona</span>
      </ion-col>
      <ion-col size="12" style="padding:0 5% 2%;font-size:14px;line-height:25px;">
        <p>Showcase your business as one that cares about your environmental impact.</p>
        <p>Attract new like-minded cliental & share your impact progression with and on behalf of your customers</p>
      </ion-col>
      <ion-col size="6">
        <RoundButton :text="'Log In'" :color="'#004F7C'" :loading="loading" @click="startBusinessLogin"/>
      </ion-col>
      <ion-col size="6">
        <RoundButton :text="'Get Started'" :color="'#004F7C'" :loading="loading" @click="startBusinessSignup"/>
      </ion-col>
      <ion-col size="12" style="padding:0 20% 0;">
        <span @click="closeModal" style="display:block;color:#969696;font-size:13px;margin:10% 0 0;">Close</span>
      </ion-col>
    </ion-row>

  </ion-content>
</template>

<script>
/* Ionic */
import { IonContent, IonRow, IonCol, IonInput, IonText, IonImg } from '@ionic/vue';
import { modalController, popoverController  } from '@ionic/vue';

/* Components */
import RoundButton from '@/components/buttons/Solid'
import Icon from '@/components/widgets/Icon.vue'

export default  {
  name: 'Signup',

  components: {
    IonContent, IonRow, IonCol, IonInput, IonText, IonImg,
    RoundButton, Icon
  },

  setup() {
    const closeModal = () => {
      popoverController.dismiss();
    };
    
    const startBusinessSignup = async () => {
      closeModal()
      
      const modal  = await modalController.create({
        component: require('@/components/modals/Authentication/BusinessSignup.vue').default,
        swipeToClose: false,
        backdropDismiss: false,
        showBackdrop: false
      }).catch(error => {
        console.log('Error loading business signup modal', error)
      })

      return modal.present();
    }

    const startBusinessLogin = async () => {
      closeModal()
      
      const modal  = await modalController.create({
        component: require('@/components/modals/Authentication/BusinessLogin.vue').default,
        cssClass: 'signin-modal three-quarter-modal',
        swipeToClose: true,
        backdropDismiss: true,
        showBackdrop: true
      }).catch(error => {
        console.log('Error loading business signup modal', error)
      })

      return modal.present();
    }

    return { 
      closeModal,
      startBusinessSignup,
      startBusinessLogin
    };
  },

}

</script>

<style scoped>
ion-content {
  --padding-top: 3vh;
  --padding-bottom: 3vh;
}

.form {
  padding:0 5%
}
</style>