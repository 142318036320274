<template>
  <ion-row v-if="!isLoading" class="ion-align-items-center" style="padding:1vh 0;">
    <ion-col size="2">
      <ProfilePhoto :url="user.profile.profile_photo" :size="46" />
    </ion-col>
    <ion-col size ="6" style="padding-left:2vw;">
      <span style="font-weight:600;color:#004F7C;font-size:11px;text-transform:uppercase;">{{user.profile.first_name}} {{user.profile.last_name}}</span>
    </ion-col>
    <ion-col size="4">
      <Follow :uid="userFields.uid" :friend="userFields.friend" />
    </ion-col>
  </ion-row>
</template>

<script>
/* Vue/Vuex */
import { useStore } from 'vuex';
import { computed, reactive, watchEffect } from 'vue';

/* Ionic */
import { IonRow, IonCol } from '@ionic/vue'

/* Components */
import Follow from '@/components/buttons/Follow';
import ProfilePhoto from '@/components/widgets/ProfilePhoto.vue'

/* Classes */
import { modalService } from "@/classes/modals"

/* Plugins */
const fb = require('@/firebase/init')

export default  {
  name: 'Users',
  components: { 
    IonRow, IonCol,
   Follow, ProfilePhoto
  },

  props: {
    user: {
      default: null,
      type: Object
    },
  },

  setup(props) {
    const { state } = useStore();
    const uid = computed(() => state.auth.uid);
    const isLoading = computed(() => state.users.loading);

    const userFields = reactive({
      uid:null,
      profile_photo: null,
      first_name: null,
      last_name:null,
      username: null,
      following: false,
    });

    watchEffect(() => {
      if (props.user) {
        userFields.uid = props.user.profile.uid
        userFields.profile_photo = props.user.profile.profile_photo
        userFields.first_name = props.user.profile.first_name
        userFields.last_name = props.user.profile.last_name
        userFields.username = props.user.profile.username

        fb.db.collection('Users').doc(uid.value).collection("Following").doc(props.user.profile.uid).get().then(snapshot => {
          if (snapshot.exists) { userFields.following=true } else { userFields.following=false }
        })
      }
    });

    const ViewUserProfile = () => {
      // console.log(props.user.uid, uid.value, props.user.id)
      if (props.user.uid === uid.value) {
        modalService.triggerProfileModal()
      } else if (uid.value === props.user.id) {
        modalService.triggerProfileModal()
      } else {
        if (props.user.uid) {
          modalService.triggerUserModal(props.user.uid)
        } else if (props.user.id) {
          modalService.triggerUserModal(props.user.id)
        }
      }
    };

    return {
      uid,
      userFields,
      isLoading,

      ViewUserProfile
    };
  },
}
</script>