const fb = require('@/firebase/init')

export default {
  loadDiscussionsBegin(state) {
    state.loading = true;
  },

  loadDiscussionsErr(state, err) {
    state.error = err;
    state.loading = true;
  },
    
  loadAllDiscussionsSuccess(state, data) {
    state.allDiscussions = data;
    state.loading = false;
  },
      
  loadFeedDiscussionsSuccess(state, discussions) {
    discussions.forEach(async discussion => {
      const doc = await fb.db.collection("Discussions").doc(discussion.id).get()
      state.followingDiscussions = {
        ...state.followingDiscussions,
        [doc.id]: {
          id: doc.id,
          ...doc.data()
        }
      }
    })
    state.loading = false;
  },

  loadHashtagDiscussionsSuccess(state, discussions) {
    discussions.forEach(async discussion => {
      const doc = await fb.db.collection("Discussions").doc(discussion.id).get()
      state.hashtagFeed = {
        ...state.hashtagFeed,
        [doc.id]: {
          id: doc.id,
          ...doc.data()
        }
      }
    })
    state.loading = false;
  },

  loadSavedDiscussionsSuccess(state, discussions) {
    discussions.forEach(async discussion => {
      const doc = await fb.db.collection("Discussions").doc(discussion.id).get()
      state.savedFeed = {
        ...state.savedFeed,
        [doc.id]: {
          id: doc.id,
          ...doc.data()
        }
      }
    })
    state.loading = false;
  },
        
  loadUserDiscussionsSuccess(state, data) {
    state.usersDiscussions = data;
    state.loading = false;
  },
    
  loadDiscussionCommentsSuccess(state, data) {
    state.comments = data;
    state.loading = false;
  }, 

  loadCommentRepliesSuccess(state, data) {
    state.comments[data.comment].replies = data.replies;
    state.loading = false;
  }, 
    
};
    