import { toastController, isPlatform } from '@ionic/vue'
import { Plugins } from "@capacitor/core"
const { Keyboard } = Plugins

export const keyboardResize = async () => {
	if (isPlatform('ios')) {
		await Keyboard.setResizeMode('none')
		document.querySelector('.dream-input').addEventListener("keyup", function (event) {
			if (event.keyCode === 13) {
				event.preventDefault()
				Keyboard.hide()
			}
		})
	}
}

/**
 * @param msg
 * @param c | primary, secondary, tertiary, success, warning, danger, light, medium, dark
 * @returns {Promise<void>}
 */
export const openToast = async (msg='', c='warning', duration=1500) => {
	const toast = await toastController.create({
		message: msg,
		duration: duration,
		color: c,
	})
	return toast.present()
}

// TODO: Look at filtering HASH
export const setHashStep = (step, path='') => {
    if(path && path.length > 1) {
		let hash = undefined===step || !step ? '' : '#'+step

        window.localStorage.setItem('onboarding_current_step', path + hash)
		console.log('onboarding_current_step', path + hash)
    }
    if(location.hash) {
		return location.hash.substring(1)
	}
	return step
}

export const chunks = function* (arr, n) {
	if (arr) {
		for (let i = 0; i < arr.length; i += n) {
			yield arr.slice(i, i + n);
		}
	}
}

