import { createStore } from 'vuex'

import auth from './modules/auth/index.js'
import causes from './modules/causes/index.js'
import users from './modules/users/index.js'
import discussions from './modules/discussions/index.js'
import transactions from './modules/transactions/index.js'

import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
TimeAgo.addDefaultLocale(en)
const timeAgo = new TimeAgo('en-US')

export const store = createStore({
    namespace:true,
    state: {
      platform: null,
      modal: [],
      alerts:[]
    },
    mutations: {

    },
    actions: {
      GET_TIME_AGO: ({}, date) => {
        return timeAgo.format(date, 'mini')
      },
    },
    modules: {
      discussions,
      transactions,
      auth,
      users,
      causes
    }
})

