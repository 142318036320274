import mutations from './mutations';

const fb = require('@/firebase/init')

const state = () => ({
  loading: true,
  causes: null
});

const actions = {
  async loadAllCauses({ commit }) {
    let queryData = [];
    try {
      await commit('loadCausesBegin');
      const query = await fb.db.collection("Causes").where('status','==',true).orderBy('name', 'desc').get()
      await query.forEach(async (doc) => {
        queryData = {
          ...queryData,
          [doc.id]: {
            id: doc.id,
            name: doc.data().name,
            measurement: doc.data().measurement,
            type: doc.data().type,
            impact: 0
          }
        }
      })
      await commit('loadCausesSuccess', queryData);
    } catch (err) {
      console.log(err);
      await commit('loadCausesErr', err);
    }
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
