<template>
  <ion-content class="ion-padding">
    <ion-row style="padding:0 0 5%;">
      <ion-col size="10" class="ixkdhkjfn">
        Add A <span class="red-title" style="padding-left:1vw;">friend</span>
      </ion-col>
      <ion-col size="2">
      </ion-col>
    </ion-row>

    <ion-row class="search-section">
      <ion-col size="12">
        <ion-input type="text" placeholder="Search A Friend" />
      </ion-col>
    </ion-row>

    <ion-row class="users-section">
      <ion-col size="12">
        <Users v-for="user in users" :key="user.uid" :user="user" />
      </ion-col>
    </ion-row>

  </ion-content>
</template>

<script>
/* Vue/Vuex */
import { useStore } from 'vuex';
import { computed, onMounted } from 'vue';


/* Ionic */
import { IonContent, IonRow, IonCol, IonInput } from '@ionic/vue';
import { modalController  } from '@ionic/vue'; 

/* Components */
import Icon from '@/components/widgets/Icon.vue'
import Users from '@/components/lists/Users.vue'

export default  {
  name: 'Friends',

  components: {
    IonContent, IonRow, IonCol, IonInput,
    Icon, Users
  },

  setup() {
    const { state, dispatch } = useStore();
    const users = computed(() => state.users.users);
    const isLoading = computed(() => state.users.loading);

    onMounted(() => {
      dispatch('loadAllUsers')
    })

    const closeModal = async () => {
      modalController.dismiss();
    };
   
    return { 
      users,
      isLoading,

      closeModal
    };
  },

}

</script>

<style scoped>
ion-content {
  background:#ffffff;
}


.users-section {
  padding:3vh 0 0;
}

</style>