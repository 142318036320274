<template>
  <ion-avatar class="text-image-avatar" :style="`background-color:${color}; width:${size}px; max-width:${size}px; height:${size}px;`">
    <img v-if="url" :src="url">
  </ion-avatar>
</template>

<script>
/* Ionic */
import { IonAvatar } from '@ionic/vue';

export default {
  name: 'ProfilePhoto',
  components: {
    IonAvatar
  },
  props: {
    url: {
      default: null,
      type: String
    },
    size: {
      default: 38,
      type: Number
    }
  },

};
</script>


<style scoped>
.text-image-avatar {
  background: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: white;
  margin: 0 auto;
}

img {
  border-radius: 100%;
}

</style>
