import mutations from './mutations';

const fb = require('@/firebase/init')

const state = () => ({
  loading: true,
  users: null,
  filteredUsers: null,
  user: null,
  dicussions:null,
  votes:null,
  causes:null,
  error:null,
});

const actions = {
  async loadAllUsers({ commit, rootState }) {
    const queryData = {};
    try {
      await commit('loadUserBegin');
      const query = await fb.db.collection('Users').orderBy('profile.last_name','desc').limit(100).get()
      await query.forEach(doc => {
        if (doc.id!==rootState.auth.uid)
        queryData[doc.id] = {
          id: doc.id,
          ...doc.data()
        }
      })
      await commit('loadUsersSuccess', queryData);
    } catch (err) {
      console.log(err);
      await commit('loadUserErr', err);
    }
  },

  async searchUserList({ commit, state }, search) {
    try {
      console.log("phrase: " + search + " | " + search.length)
      if (search.length > 2) {

        const results = []
        await fb.db.collection("Users")
            .where("profile.username", "==", search.toLowerCase())
            // .orderBy("profile.username", "asc")
            .get()
            .then(users => {
              users.forEach((_user) => {
                const u = _user.data()
                u.uid = _user.id
                results.push(u)
              })
            })
            .catch(error => {
              console.log('ERROR GETTING USERS', error)
            })

        console.log('results', results.length)

        /*
        const data = users.filter( user => {
          const name = user.profile.first_name + ' ' + user.profile.last_name
          return name.toLowerCase().includes(search.toLowerCase())
        })
         */

        state.filteredUsers = results

      } else {
        state.filteredUsers = state.users
      }
    } catch (err) {
      console.log(err);
      await commit('loadUserErr', err);
    }
  },

  async loadUserProfile({ commit }, uid) {
    try {
      await commit('loadUserBegin');
      const user = await fb.db.collection("Users").doc(uid).get()
      await commit('loadUserProfileSuccess', user.data());
    } catch (err) {
      console.log(err);
      await commit('loadUserErr', err);
    }
  },
  
  async loadUserDiscussions({ commit }, uid) {
    const queryData = {};
    try {
      await commit('loadUserBegin');
      const query = await fb.db.collection("Discussions").where('uid','==',uid).get()
      await query.forEach(doc => {
        queryData[doc.id] = {
          id: doc.id,
          ...doc.data()
        }
      })
      await commit('loadUserDiscussionsSuccess', queryData);
    } catch (err) {
      console.log(err);
      await commit('loadUserErr', err);
    }
  },

  async loadUserVotingHistory({ commit }, uid) {
    const queryData = {};
    try {
      await commit('loadUserBegin');
      const query = await fb.db.collection("Users").doc(uid).collection("Votes").get()
      await query.forEach(doc => {
        queryData[doc.id] = {
          id: doc.id,
          ...doc.data()
        }
      })
      await commit('loadUserVotingHistorySuccess', queryData);
    } catch (err) {
      console.log(err);
      await commit('loadUserErr', err);
    }
  },
  
  async followUser ({ rootState }, uid) {
    if(!rootState.auth.uid) {
        console.log('NO ID, cannot follow')
        return false
    }

    const dbCurrentUser = fb.db.collection("Users").doc(rootState.auth.uid)
    const dbFollowedUser = fb.db.collection("Users").doc(uid)

    //Add User to Current User's Following List
    await dbCurrentUser.collection("Following").doc(uid).set({
        created: new Date(),
        uid: uid,
    })

    //Add Current User to User's Followers List
    await dbFollowedUser.collection("Followers").doc(rootState.auth.uid).set({
        created: new Date(),
        uid: rootState.auth.uid,
    })
  },

  async unfollowUser ({ rootState }, uid) {
    if(!rootState.auth.uid) {
      console.log('NO ID, cannot unfollow')
      return false
    }

    const dbCurrentUser = fb.db.collection("Users").doc(rootState.auth.uid)
    const dbFollowedUser = fb.db.collection("Users").doc(uid)

    //Remove User From Following List
    dbCurrentUser.collection("Following").doc(uid).delete()

    //Remove Current User From Followers List
    dbFollowedUser.collection("Followers").doc(rootState.auth.uid).delete()
  },

  async setUserProfileImage ({ state }, imageUrl) {
    state.user.profile.profile_image = imageUrl
  },

  async setUserNotificationCount ({ state }, count) {
    if (state.user) {
      state.user.notifications = count
    }
  }

};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
