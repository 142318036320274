import { createRouter, createWebHistory } from '@ionic/vue-router';
const fb = require('@/firebase/init')

const routes = [
  { name:'dashboard',	 		path: '/', 		component: () => import('@/views/Dashboard.vue'), meta: { requiresAuth: true } },

  { name:'splash',	 		path: '/onboarding', 		component: () => import('@/views/SplashScreen.vue'), meta: { requiresAuth: false } },

  { name:'search',	 		path: '/search', 		component: () => import('@/views/Search.vue'), meta: { requiresAuth: true } },
  { name:'notifications',	 		path: '/notifications', 		component: () => import('@/views/Notifications.vue'), meta: { requiresAuth: true } },
  { name:'profile',	 		path: '/profile', 		component: () => import('@/views/Account/Profile.vue'), meta: { requiresAuth: true } },


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: "active",
  linkExactActiveClass: 'active',
  routes
})

router.beforeEach((to, from, next) => {

  const requiresAuth = to.matched.some(m => m.meta.requiresAuth)

  fb.authenticate.onAuthStateChanged(authUser => {
    if (requiresAuth && !authUser) {
      next({ name:'splash' })
    } else {
      next()
    }
  })

})

export default router
