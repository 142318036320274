<template>
  <ion-row v-if="type==='dashboard'" style="text-align:center">
    <ion-col size="12">
      <ion-slides :options="{slidesPerView:4, spaceBetween:15,pagination: {el: '.swiper-pagination',type: 'bullets'}}">  
        <ion-slide v-for="(impact,index) in items" :key="index">
          <Impact :item="impact" :selectable="selectable" />
        </ion-slide>
      </ion-slides>
    </ion-col>
    <ion-col size="12">
      <div class="swiper-pagination"></div>
    </ion-col>
  </ion-row>
  <ion-row v-else-if="type==='profile'" style="text-align:center">
    <ion-col size="12" class="ion-align-self-center" style="padding:2vh 0;" v-if="user.profile.role==='consumer'">
      <span style="font-size:12px;color:#B1B1B1;text-transform:uppercase;font-weight:900">Make your first donation</span>
      <OutlinedButton :text="'DONATE'" :textcolor="'#00777E'" :color="'#00777E'" style="width:50%;margin:1vh auto 0" />
    </ion-col>
    <ion-col size="12" class="ion-align-self-center" style="padding:2vh 0;" v-else-if="user.profile.role==='merchant'">
      <span style="font-size:12px;color:#B1B1B1;text-transform:uppercase;font-weight:900">Make your first impact</span>
      <OutlinedButton :text="'Create Impact'" :textcolor="'#00777E'" :color="'#00777E'" style="width:75%;margin:3vh auto 0" @click="createNewTransactions" />
    </ion-col>
  </ion-row>
</template>

<script>
/* Vue/Vuex */
import { useStore } from 'vuex';
import { computed } from 'vue';

/* Ionic */
import { IonSlides, IonSlide, IonRow, IonCol } from '@ionic/vue'

/* Components */
import Impact from '@/components/cards/Impact.vue'
import OutlinedButton from '@/components/buttons/Outlined.vue'

/* Classes */
import { modalService } from "@/classes/modals"
import { popoverService } from "@/classes/popovers"

export default {
  props: {
    items: {
      default: null,
      type: Object
    },
    type: {
      default: 'dashboard',
      type: String
    },
    selectable: {
      default: false,
      type: Boolean
    }
  },

  components: {
    IonSlides, IonSlide, IonRow, IonCol,
    Impact, OutlinedButton
  },

  setup() {
    const { state } = useStore();
    const user = computed(() => state.auth.user);
    const tags = computed(() => state.auth.tags);

    const createNewTransactions = async () => {
      if (!tags.value.includes('terms-conditions-accepted')) { return popoverService.triggerPendingAgreement('terms and conditions') }
      else if (!tags.value.includes('service-agreement-accepted')) { return popoverService.triggerPendingAgreement('service agreement') }
      else { return modalService.triggerCreateNewTransaction() }
    }

    return {
      user,
      
      createNewTransactions
    }
  }

}
</script>

<style>
.swiper-pagination {
  width: 100%;
  padding:2% 0
}	

.swiper-pagination .swiper-pagination-bullet {
  background: #8E8E8E !important;
  width:8px;
  height:8px;
  margin: 0 1%
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: #004f7c  !important;
}
</style>