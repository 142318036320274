<template>
  <ion-content class="ion-padding">
    <ion-row style="padding:0 0 5%;text-align:center;">
      <ion-col size="12">
        <span class="profile-photo">
          <img v-if="user.profile.profile_photo" :src="user.profile.profile_photo">
        </span>
      </ion-col>
      <ion-col size="12">
        <span style="font-weight:600;color:#004F7C;font-size:13px;text-transform:uppercase;">{{user.profile.company_name}}</span>
      </ion-col>
      <ion-col size="12" style="padding:6vh 0 0;">
        <h4 style="font-size:16px;text-transform:uppercase;font-weight:600;color:#004F7C;padding:2vh 0">Update Location</h4>
        <div style="height:2px;width:30%;margin:0 auto;background:#CCD57E;"></div>
      </ion-col>
    </ion-row>


  </ion-content>
</template>

<script>
/* Vue/Vuex */
import { useStore } from 'vuex';
import { computed, onMounted, onUnmounted, reactive } from 'vue';


/* Ionic */
import { IonContent, IonRow, IonCol, IonInput } from '@ionic/vue';

/* Components */
import Icon from '@/components/widgets/Icon.vue'
import OutlinedButton from '@/components/buttons/Outlined'

/* Classes */
import { modalService } from "@/classes/modals"
import { popoverService } from "@/classes/popovers"

export default  {
  name: 'Friends',

  components: {
    IonContent, IonRow, IonCol, IonInput,
    Icon, OutlinedButton
  },

  setup() {
    const { state, dispatch } = useStore();
    const user = computed(() => state.auth.user);
    const uid = computed(() => state.auth.uid);
    const modals = computed(() => state.modal);

    let formState = reactive({
      isLoading: true,
      staff: []
    });

    onMounted(() => {
      
    })

    onUnmounted(() => {
      delete modals.value['create-staff']
    })

    const closeModal = async () => {
      modalService.triggerCreateStaffModal();
    };

    const createStaffAccount = async () => {
      popoverService.triggerCreateNewStaff()
    };
   
    return { 
      user,
      formState,

      closeModal,
      createStaffAccount
    };
  },

}

</script>

<style scoped>

.profile-photo {
  display:block;
  width:99px;
  height:99px;
  margin:0 auto;
  border:3px solid #004F7C;
  background:#9F9F9F;
  border-radius:100%
}

.profile-photo img { border-radius:100%;}

</style>