<template>
  <ion-content>
      <ion-row class="ion-text-center">
        <ion-col size="12" class="ixkdhkjfn" style="font-size:18px;">
          Welcome to <span class="red-title" style="padding-left:1vw;font-size:28px;">halona</span>
        </ion-col>
        <ion-col size="12" class="nshroxcdn" style="font-size:22px;padding:10% 0;">
          Unfortunately, you must agree to the {{type}} before continuing.
        </ion-col>
        <ion-col size="12" style="padding:0 10% 5%;">
          <RoundButton :text="'View Agreement'" :color="'#00777E'" :loading="false" @click="viewAgreementModal" />
        </ion-col>
        <ion-col size="12" style="font-size:14px;color:#929292;">
          <span @click="closePopover">Close</span>
        </ion-col>
      </ion-row>
  </ion-content>
</template>

<script>
/* Ionic */
import { IonPage, IonContent, IonRow, IonCol } from '@ionic/vue';
import { popoverController  } from '@ionic/vue';

/* Classes */
import { modalService } from "@/classes/modals"

/* Components */
import RoundButton from '@/components/buttons/Solid';

export default  {
  name: 'intro',

  props: {
    type: {
      type: String,
      default: null
    }
  },

  components: {
    IonPage, IonContent, IonRow, IonCol,
    RoundButton
  },

  setup(props) {
    const closePopover = async () => {
      return popoverController.dismiss()
    }
      
    const viewAgreementModal = async () => {
      closePopover()
      return modalService.triggerViewAgreementsModal(props.type)
    }

    return {
      closePopover,
      viewAgreementModal
    };
  },

}
</script>
<style scoped>

ion-content {
  --padding-top: 3vh !important;
  --padding-bottom: 3vh !important;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

ion-row {
  padding: 0 3vw
}



</style>
