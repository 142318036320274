<template>
  <ion-content>
      <ion-row class="ion-text-center">
        <ion-col size="12" class="ixkdhkjfn" style="font-size:18px;">
          Add user <span class="red-title" style="padding-left:1vw;font-size:28px;">account</span>
        </ion-col>
        <ion-col size="12" style="font-size:22px;padding:10% 0;">
          <ion-input inputmode="tel" type="text" v-model="formState.phone" placeholder="Phone Number" @input="formatPhoneNumber($event)" />
          <span v-if="formState.error" style="font-size:13px;color:#DC8D83;">{{formState.error}}</span>
        </ion-col>
        <ion-col size="12" style="padding:0 10% 5%;">
          <RoundButton :text="'Next'" :color="'#00777E'" :loading="false" @click="linkUserAccount" />
          <span @click="closePopover" style="font-size:12px;">Skip</span>
        </ion-col>
        <ion-col size="12" style="font-size:12px;color:#929292;">
          <span @click="linkToBusiness">Add Another Business To Transaction</span>
        </ion-col>
      </ion-row>
  </ion-content>
</template>

<script>
/* Vue/Vuex */
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

/* Ionic */
import { IonPage, IonContent, IonRow, IonCol, IonInput } from '@ionic/vue';
import { popoverController  } from '@ionic/vue';

/* Components */
import RoundButton from '@/components/buttons/Solid';

/* Classes */
import { popoverService } from "@/classes/popovers"

export default  {
  name: 'intro',

  components: {
    IonPage, IonContent, IonRow, IonCol, IonInput,
    RoundButton
  },

  setup() {
    const { state } = useStore();

    let formState = reactive({
      isLoading: true,
      error:null,
      phone:''
    });

    const closePopover = async () => {
      return popoverController.dismiss()
    }

    const formatPhoneNumber = async (e) => {
      const x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      formState.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    }

    const linkUserAccount = async () => {
      formState.error = null

      const number = ('+1'+formState.phone).replace(/[^\d\+]/g,"")
      console.log(number.length)
      if(number.length===12) {
        state.transactions.account.phone = number
        return closePopover()
      } else {
        formState.error = 'invalid phone number.'
      }
    }

    const linkToBusiness = async () => {
      popoverService.triggerLinkToBusiness()
    }
      
    return {
      formState,

      closePopover,
      formatPhoneNumber,
      linkUserAccount,
      linkToBusiness
    };
  },

}
</script>
<style scoped>

ion-content {
  --padding-top: 3vh !important;
  --padding-bottom: 3vh !important;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

ion-row {
  padding: 0 3vw
}

ion-item {
  border:1px solid #ACACAC;
  border-radius: 25px;
  margin: 0 0 3vh;
}

ion-input {
  font-size:14px !important;
  color:#0f0f0f !important;
}



</style>
