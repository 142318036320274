<template>
  <ion-content class="ion-padding">
    <ion-row style="padding:0 0 5%;">
      <ion-col size="12" class="ixkdhkjfn ion-text-center">
        Create New <span class="red-title" style="padding-left:1vw;">Transaction</span>
      </ion-col>
      <ion-col size="12" class="ion-text-center" style="margin:10% 0 0">
        <span class="profile-photo">
          <img v-if="formState.isLoading && formState.profilePhoto" :src="formState.profilePhoto">
        </span>
      </ion-col>
      <ion-col size="12" class="ion-text-center" style="font-weight:600;color:#004F7C;font-size:13px;text-transform:uppercase;">
        <span v-if="formState.firstName && formState.lastName">{{formState.firstName}} {{formState.lastName}}</span>
        <span v-else-if="account.email">{{account.email}}</span>
        <span v-else-if="account.phone">{{account.phone}}</span>
      </ion-col>
    </ion-row>

    <ion-row>
      <ion-col size="12" v-if="transaction.impacts.length>0">
        <ion-row v-for="(item,index) in transaction.impacts" :key="index" class="lkjsnnb">
          <ion-col size="2" class="ion-text-left">
            <Impact :type="item.cause.type" />
          </ion-col>
          <ion-col size="9" class="ion-text-left">
            <span class="ixkdhkjfn" style="display:block;">{{item.cause.long_name}}  </span>
            <span class="skdnsh">{{item.title}}</span>
            <span class="lndjgb" v-if="item.format!='percentage'">${{(item.total * item.cause.cost).toFixed(2)}} Impact Cost</span>
            <span class="lndjgb" v-else-if="item.format==='percentage'">${{item.total.toFixed(2)}} Impact Cost</span>
          </ion-col>
          <ion-col size="1" class="ion-text-right">
            <svg width="19.799" height="19.799" viewBox="0 0 19.799 19.799" @click="deleteImpact(index)">
              <g transform="translate(-266.636 -391.565)">
                <rect width="15" height="15" rx="7.5" transform="translate(269 394)" fill="#8b8b8b"/>
                <text transform="translate(273 405) rotate(45)" fill="#fff" font-size="14" font-family="Montserrat-SemiBold, Montserrat" font-weight="600" letter-spacing="0.04em"><tspan x="-4.13" y="0">+</tspan></text>
              </g>
            </svg>
          </ion-col>
        </ion-row>
      </ion-col>
      <ion-col size="12" class="nshroxcdn ion-text-center" style="padding:5% 12%;font-size:25px;" v-else>
        Add the first impact to the transaction
      </ion-col>
      <ion-col size="12">
        <ion-row style="margin:10% 0 0">
          <ion-col size="6">
            <OutlinedButton :text="'New Impact'" :textcolor="'#00777E'" :color="'#00777E'" style="font-weight:600;font-size:13px;" @click="addNewImpact" />
          </ion-col>
          <ion-col size="6">
            <OutlinedButton :text="'Add Preset'" :textcolor="'#00777E'" :color="'#00777E'" style="font-weight:600;font-size:13px;" />
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>

    <ion-row class="ion-text-center">
      <ion-col size="12" style="font-size:14px;padding: 5% 0;">
        You Have 0 Impact Credits To Apply
      </ion-col>
      <ion-col size="12" style="padding:0 15%;">
        <RoundButton v-if="transaction.impacts.length>0" :text="'$'+transaction.cost.toFixed(2)+' total'" :color="'#00777E'" :loading="false" @click="saveTransaction" />
        <RoundButton v-else  :text="'Confirm'" :color="'#707070'" :loading="false" />
      </ion-col>
      <ion-col size="12" style="font-size:14px;padding: 5% 0 0;">
        <span @click="closeModal">Cancel</span>
      </ion-col>
    </ion-row>


  </ion-content>
</template>

<script>
/* Vue/Vuex */
import { useStore } from 'vuex';
import { computed, onMounted, onUnmounted, reactive, watchEffect } from 'vue';

/* Ionic */
import { IonContent, IonRow, IonCol, IonInput } from '@ionic/vue';
import { modalController, alertController } from '@ionic/vue'; 

/* Components */
import Icon from '@/components/widgets/Icon.vue'
import Impact from '@/components/icons/Impact.vue'
import OutlinedButton from '@/components/buttons/Outlined'
import RoundButton from '@/components/buttons/Solid';

/* Plugins */
const fb = require('@/firebase/init')

/* Classes */
import { popoverService } from "@/classes/popovers"

export default  {
  name: 'Friends',

  components: {
    IonContent, IonRow, IonCol, IonInput,
    Icon, Impact, OutlinedButton, RoundButton
  },

  setup() {
    const { state } = useStore();
    const uid = computed(() => state.auth.uid);
    const isLoading = computed(() => state.users.loading);
    const account = computed(() => state.transactions.account);
    const transaction = computed(() => state.transactions.transaction);

    let formState = reactive({
      isLoading: true,
      uid: '',
      firstName: '',
      lastName: '',
      profilePhoto: null,
    });

    onMounted(() => {
      popoverService.triggerLinkTransaction()
    })
    
    onUnmounted(() => {
      state.transactions.transaction.impacts = []
      state.transactions.transactioncost = 0
    })

    const closeModal = async () => {
      modalController.dismiss();
    };

    const loadUserProfile = async (query) => {
      if (!query.empty) {
        formState.uid = query.docs[0].id
        formState.firstName = query.docs[0].data().profile.first_name
        formState.lastName = query.docs[0].data().profile.last_name
        formState.profilePhoto = query.docs[0].data().profile.profile_photo
      }
    };

    const addNewImpact = async () => {
      popoverService.triggerAddNewImpact()
    }

    const saveTransaction = async () => {
      await fb.db.collection("Transactions").add({
        'created': new Date(),
        'receiver': {uid:formState.uid ? formState.uid : null, ...account.value},
        'sender':uid.value,
        'claimed':false,
        'format': transaction.value.format,
        'cost':transaction.value.cost,
        'cause':transaction.value.cause,
      }).then(doc => {
        console.log("Created Transaction",doc)
        for(let i=0;i<transaction.value.impacts.length;i++) {
          fb.db.collection("Transactions").doc(doc.id).collection('Impact').add({
            'created':new Date(),
            ...transaction.value.impacts[i]
          })
        }
      }).then(async () => {
        const alert = await alertController
        .create({
          header: 'Successful Transaction!',
          message: 'Together we can change the world!',
          buttons: [
            {
              text: 'Close',
              handler: () => {
                closeModal()
              },
            },
          ],
        });
        return alert.present();
      })
    }

    const deleteImpact = async (index) => {
      transaction.value.impacts.splice(index,1)
    }

    watchEffect(async () => {
      if (account && account.value.phone) {
        const query = await fb.db.collection('Users').where('profile.role','==','consumer').where('profile.phone','==',account.value.phone).get()
        await loadUserProfile(query)
      }
    })
   
    return { 
      isLoading,
      formState,
      transaction,
      account,

      closeModal,
      addNewImpact,
      saveTransaction,
      deleteImpact
    };
  },

}

</script>

<style scoped>

.profile-photo {
  display:block;
  width:75px;
  height:75px;
  margin:0 auto;
  border:2px solid #CCD57E;
  background:#9F9F9F;
  border-radius:100%
}

.profile-photo img { border-radius:100%;}

.skdnsh {
  display:block;
  font-size:13px;
  padding:1% 0 2%;
  color:#8d8d8d
}

.lndjgb {
  display:block;
  font-size:13px;
  color:#DC8D83
}

.lkjsnnb {
  margin: 5% 0;
  box-shadow: 0px 1px 4px #09090910;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 2px solid #EBEBEB;
  padding: 2%;
}

</style>