export default {
  loadUserBegin(state) {
    state.loading = true;
  },

  loadUserErr(state, err) {
    state.error = err;
    state.loading = false;
  },
  
  loadUsersSuccess(state, data) {
    state.users = data;
    state.filteredUsers = data
    state.loading = false;
  },
    
  loadUserProfileSuccess(state, data) {
    state.user = data;
    state.loading = false;
  },  
  
  loadUserDiscussionsSuccess(state, data) {
    state.discussions = data;
    state.loading = false;
  },

  loadUserVotingHistorySuccess(state, data) {
    state.votes = data;
    state.loading = false;
  },
  
  };
  